class Wall {
  /**
   * @param {number} width width of the wall
   * @param {number} height height of the wall
   * @param {Door[]} door doors on this wall
   * @param {Window[]} window windows on this wall
   * @param {UnitOfLengths} unitOfLength all the dimensions of the wall and it's parts (windows, doors, etc.) are in this unit of lenght
   */
  constructor(width, height, doors, windows, unitOfLength) {
    this.width = width;
    this.height = height;
    this.doors = doors;
    this.windows = windows;
    this.unitOfLength = unitOfLength;
  }
}

export default Wall;
