import React from "react";
import PropTypes from "prop-types";

// Components
import { FormFieldsTitle } from "components/commonStyledComponents";

// Helper
import localizer from "localization/localizer";
import { appendUnit } from "helpers/StringHelper";
import { wallHeightLimits, wallWidthLimits } from "./DimensionsConstraints";
import { useFormikContext } from "formik";
import { convertDimension } from "helpers/WallHelpers";

// Styling
import styled from "styled-components";
import NumberField from "components/NumberField";

const LayoutGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 10% 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    "title title title"
    "width gap height";
`;

const FieldsTitle = styled(FormFieldsTitle)`
  grid-area: title;
`;

const WidthField = styled(NumberField)`
  grid-area: width;
`;

const HeightField = styled(NumberField)`
  grid-area: height;
`;

/**
 * Fields meant to be used in a Formik form to input the width and height of a wall
 */
const WallDimensionsFormFields = ({ widthField, heightField, isMetric }) => {
  const { values } = useFormikContext();
  const unitOfLength = values.unitOfLength;

  return (
    <LayoutGrid>
      <FieldsTitle>{localizer.wall}</FieldsTitle>
      <WidthField
        autoFocus
        fieldName={widthField.name}
        label={appendUnit(localizer.width, unitOfLength)}
        required
        step="0.05"
        min={`${convertDimension(widthField.min, unitOfLength)}`}
        max={`${convertDimension(wallWidthLimits.max, unitOfLength)}`}
      />
      <HeightField
        fieldName={heightField.name}
        label={appendUnit(localizer.height, unitOfLength)}
        type="number"
        required
        step="0.05"
        min={`${convertDimension(heightField.min, unitOfLength)}`}
        max={`${convertDimension(wallHeightLimits.max, unitOfLength)}`}
      />
    </LayoutGrid>
  );
};

WallDimensionsFormFields.propTypes = {
  /** Attributes of the Formik Field that will be used for the input of the wall width */
  widthField: PropTypes.shape({
    name: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
  }).isRequired,
  /** Attributes of the Formik Field that will be used for the input of the wall height */
  heightField: PropTypes.shape({
    name: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
  }).isRequired,
};

export default WallDimensionsFormFields;
