// Sunburst
import SunBurst90510 from "resources/images/panoramiques/SunBurst/Boutique_Sunburst_90510_Packshot.png";
import SunBurst90511 from "resources/images/panoramiques/SunBurst/Boutique_Sunburst_90511_Packshot.png";

// Babylon
import Babylon97000 from "resources/images/panoramiques/Babylon/Babylon_BetweenRivers_97000.png";
import Babylon97001 from "resources/images/panoramiques/Babylon/Babylon_BetweenRivers_97001.png";
import Babylon97002 from "resources/images/panoramiques/Babylon/Babylon_BetweenRivers_97002.png";
import Babylon97003 from "resources/images/panoramiques/Babylon/Babylon_BetweenRivers_97003.png";

// Banyan
import Banyan11530 from "resources/images/panoramiques/Banyan/Alaya_Banyan_11530_Flatshot.png";
import Banyan11530Dimensions from "resources/images/panoramiques/Banyan/Alaya_Banyan_11530_View_Print.png";

import Banyan11531 from "resources/images/panoramiques/Banyan/Alaya_Banyan_11531_Flatshot.png";
import Banyan11531Dimensions from "resources/images/panoramiques/Banyan/Alaya_Banyan_11531_View_Print.png";

// CielTropical
import CielTropical97650 from "resources/images/panoramiques/CielTropical/DécorsPanoramiques_CielTropical_97650_Flatshot.png";
import CielTropical97650Dimensions from "resources/images/panoramiques/CielTropical/DécorsPanoramiques_CielTropical_97650_View_Print.png";

import CielTropical97651 from "resources/images/panoramiques/CielTropical/DécorsPanoramiques_CielTropical_97651_Flatshot.png";
import CielTropical97651Dimensions from "resources/images/panoramiques/CielTropical/DécorsPanoramiques_CielTropical_97651_View_Print.png";

import CielTropical97652 from "resources/images/panoramiques/CielTropical/DécorsPanoramiques_CielTropical_97652_Flatshot.png";
import CielTropical97652Dimensions from "resources/images/panoramiques/CielTropical/DécorsPanoramiques_CielTropical_97652_View_Print.png";

// HillsOfSapa
import HillsOfSapa97590 from "resources/images/panoramiques/HillsOfSapa/DécorsPanoramiques_HillsofSapa_97590_Flatshot.png";
import HillsOfSapa97590Dimensions from "resources/images/panoramiques/HillsOfSapa/DécorsPanoramiques_HillsofSapa_97590_View_Print.png";

import HillsOfSapa97591 from "resources/images/panoramiques/HillsOfSapa/DécorsPanoramiques_HillsofSapa_97591_Flatshot.png";
import HillsOfSapa97591Dimensions from "resources/images/panoramiques/HillsOfSapa/DécorsPanoramiques_HillsofSapa_97591_View_Print.png";

// LeJardinMystique
import LeJardinMystique97550 from "resources/images/panoramiques/LeJardinMystique/DécorsPanoramiques_Lejardinmystique_97550_Flatshot.png";
import LeJardinMystique97550Dimensions from "resources/images/panoramiques/LeJardinMystique/DécorsPanoramiques_LeJardinMystique_97550.png";

import LeJardinMystique97551 from "resources/images/panoramiques/LeJardinMystique/DécorsPanoramiques_Lejardinmystique_97551_Flatshot.png";
import LeJardinMystique97551Dimensions from "resources/images/panoramiques/LeJardinMystique/DécorsPanoramiques_LeJardinMystique_97551.png";

//LesMysteresDeMadagascar
import LesMysteresDeMadagascar97530 from "resources/images/panoramiques/LesMysteresDeMadagascar/DécorsPanoramiques_LesmystèresdeMadagascar_97530_Flatshot.png";
import LesMysteresDeMadagascar97530Dimensions from "resources/images/panoramiques/LesMysteresDeMadagascar/DécorsPanoramiques_LesMysteresdeMadagascar_97530_View_Print.png";

import LesMysteresDeMadagascar97531 from "resources/images/panoramiques/LesMysteresDeMadagascar/DécorsPanoramiques_LesmystèresdeMadagascar_97531_Flatshot.png";
import LesMysteresDeMadagascar97531Dimensions from "resources/images/panoramiques/LesMysteresDeMadagascar/DécorsPanoramiques_LesMysteresdeMadagascar_97531_View_Print.png";

// LesSongesToscans
import LesSongesToscans97520 from "resources/images/panoramiques/LesSongesToscans/DécorsPanoramiques_LessongesToscans_97520_Flatshot.png";
import LesSongesToscans97520Dimensions from "resources/images/panoramiques/LesSongesToscans/DécorsPanoramiques_LesSongesToscans_97520_View_Print.png";

import LesSongesToscans97521 from "resources/images/panoramiques/LesSongesToscans/DécorsPanoramiques_LessongesToscans_97521_Flatshot.png";
import LesSongesToscans97521Dimensions from "resources/images/panoramiques/LesSongesToscans/DécorsPanoramiques_LesSongesToscans_97521_View_Print.png";

// MilkAndHoney
import MilkAndHoney97630 from "resources/images/panoramiques/MilkAndHoney/DécorsPanoramiques_MilkandHoney_97630_Flatshot.png";
import MilkAndHoney97630Dimensions from "resources/images/panoramiques/MilkAndHoney/DécorsPanoramiques_MilkandHoney_97630_View_Print.png";

import MilkAndHoney97631 from "resources/images/panoramiques/MilkAndHoney/DécorsPanoramiques_MilkandHoney_97631_Flatshot.png";
import MilkAndHoney97631Dimensions from "resources/images/panoramiques/MilkAndHoney/DécorsPanoramiques_MilkandHoney_97631_View_Print.png";

// Rovine
import Rovine42560 from "resources/images/panoramiques/Rovine/Sculptura_Rovine_42560_Flatshot.png";
import Rovine42560Dimensions from "resources/images/panoramiques/Rovine/ImpressieRovine_42560.png";

import Rovine42561 from "resources/images/panoramiques/Rovine/Sculptura_Rovine_42561_Flatshot.png";
import Rovine42561Dimensions from "resources/images/panoramiques/Rovine/ImpressieRovine_42561.png";

import Rovine42562 from "resources/images/panoramiques/Rovine/Sculptura_Rovine_42562_Flatshot.png";
import Rovine42562Dimensions from "resources/images/panoramiques/Rovine/ImpressieRovine_42562.png";

// SecretSilhouettes
import SecretSilhouettes97720 from "resources/images/panoramiques/SecretSilhouettes/DécorsPanoramiques_SecretSilhouettes_97720_Flatshot.png";
import SecretSilhouettes97720Dimensions from "resources/images/panoramiques/SecretSilhouettes/DécorsPanoramiques_SecretSilhouettes_97720_View_Print.png";

import SecretSilhouettes97721 from "resources/images/panoramiques/SecretSilhouettes/DécorsPanoramiques_SecretSilhouettes_97721_Flatshot.png";
import SecretSilhouettes97721Dimensions from "resources/images/panoramiques/SecretSilhouettes/DécorsPanoramiques_SecretSilhouettes_97721_View_Print.png";

// SilkRoadGarden
import SilkRoadGarden72000 from "resources/images/panoramiques/SilkRoadGarden/Expedition_SilkRoadGarden_72000_Flatshot.png";
import SilkRoadGarden72000Dimensions from "resources/images/panoramiques/SilkRoadGarden/Expedition_SilkRoadGarden_72000.png";

import SilkRoadGarden72001 from "resources/images/panoramiques/SilkRoadGarden/Expedition_SilkRoadGarden_72001_Flatshot.png";
import SilkRoadGarden72001Dimensions from "resources/images/panoramiques/SilkRoadGarden/Expedition_SilkRoadGarden_72001.png";

// ValleDeVinales
import ValleDeVinales97710 from "resources/images/panoramiques/ValleDeVinales/DécorsPanoramiques_ValledeVinales_97710_Flatshot.png";
import ValleDeVinales97710Dimensions from "resources/images/panoramiques/ValleDeVinales/DécorsPanoramiques_ValledeVinales_97710_View_Print.png";

import ValleDeVinales97711 from "resources/images/panoramiques/ValleDeVinales/DécorsPanoramiques_ValledeVinales_97711_Flatshot.png";
import ValleDeVinales97711Dimensions from "resources/images/panoramiques/ValleDeVinales/DécorsPanoramiques_ValledeVinales_97711_View_Print.png";

// Zerzura
import Zerzura74060 from "resources/images/panoramiques/Zerzura/Kharga_Zerzura_74060_Flatshot.png";
import Zerzura74060Dimensions from "resources/images/panoramiques/Zerzura/Kharga_Zerzura_74060_View_Print.png";

import Zerzura74061 from "resources/images/panoramiques/Zerzura/Kharga_Zerzura_74061_Flatshot.png";
import Zerzura74061Dimensions from "resources/images/panoramiques/Zerzura/Kharga_Zerzura_74061_View_Print.png";

import Zerzura74062 from "resources/images/panoramiques/Zerzura/Kharga_Zerzura_74062_Flatshot.png";
import Zerzura74062Dimensions from "resources/images/panoramiques/Zerzura/Kharga_Zerzura_74062_View_Print.png";

// Tropic
import Tropic75500 from "resources/images/panoramiques/Tropic/Monsoon_Tropic_75500_Flatshot.png";
import Tropic75500Dimensions from "resources/images/panoramiques/Tropic/Monsoon_Tropic_75500.png";

import Tropic75501 from "resources/images/panoramiques/Tropic/Monsoon_Tropic_75501_Flatshot.png";
import Tropic75501Dimensions from "resources/images/panoramiques/Tropic/Monsoon_Tropic_75501.png";

// Reverie Tropicale
import ReverieTropicale26770 from "resources/images/panoramiques/ReverieTropicale/EssentialsLesNaturels_ReverieTropicale_26770_Packshot.png";
import ReverieTropicale26770Dimensions from "resources/images/panoramiques/ReverieTropicale/EssentialsLesNaturels_ReverieTropicale_26770_View.png";

import ReverieTropicale26771 from "resources/images/panoramiques/ReverieTropicale/EssentialsLesNaturels_ReverieTropicale_26771_Packshot.png";
import ReverieTropicale26771Dimensions from "resources/images/panoramiques/ReverieTropicale/EssentialsLesNaturels_ReverieTropicale_26771_View.png";

// Doudeville
import Doudeville59590 from "resources/images/panoramiques/Doudeville/EssentialsWashedLinen_Doudeville_59590.png";
import Doudeville59591 from "resources/images/panoramiques/Doudeville/EssentialsWashedLinen_Doudeville_59591.png";
import Doudeville59592 from "resources/images/panoramiques/Doudeville/EssentialsWashedLinen_Doudeville_59592.png";

import Doudeville59590Dimensions from "resources/images/panoramiques/Doudeville/EssentialsWashedLinen_Doudeville_59590_Dimensions.png";
import Doudeville59591Dimensions from "resources/images/panoramiques/Doudeville/EssentialsWashedLinen_Doudeville_59591_Dimensions.png";
import Doudeville59592Dimensions from "resources/images/panoramiques/Doudeville/EssentialsWashedLinen_Doudeville_59592_Dimensions.png";

// Sherwood
import Sherwood59640 from "resources/images/panoramiques/Sherwood/EssentialsBrushedSuede_Sherwood_59640.png";
import Sherwood59641 from "resources/images/panoramiques/Sherwood/EssentialsBrushedSuede_Sherwood_59641.png";
import Sherwood59642 from "resources/images/panoramiques/Sherwood/EssentialsBrushedSuede_Sherwood_59642.png";

import Sherwood59640Dimensions from "resources/images/panoramiques/Sherwood/EssentialsBrushedSuede_Sherwood_59640_Dimensions.png";
import Sherwood59641Dimensions from "resources/images/panoramiques/Sherwood/EssentialsBrushedSuede_Sherwood_59641_Dimensions.png";
import Sherwood59642Dimensions from "resources/images/panoramiques/Sherwood/EssentialsBrushedSuede_Sherwood_59642_Dimensions.png";

import UnitOfLengths from "classes/UnitOfLengths";

export const panoramiques = [
  {
    name: "Doudeville",
    scene: Doudeville59590,
    collectionName: "Essentials Washed Linen",
    collectionCode: "24-3988",
    description: {
      en: "This panoramic, printed on real textile, was named after Doudeville, a town in Normandy that's known as the linen capital of France. The subtle design was inspired by the origins of linen and the many flax fields in the coastal areas of Western Europe. After harvesting, the flax stalks are left in the fields, creating a striking striped landscape with beautiful colour variegations. This image, which is so typical of Doudeville and other flax-growing regions, inspired this intriguing striped pattern.",
      nl: "Dit panorama, geprint op echt textiel, werd vernoemd naar de gelijknamige Normandische gemeente Doudeville, in Frankrijk ook wel de ‘capitale du lin’ of linnenhoofdstad genoemd. Het subtiele design vond z’n inspiratie in de oorsprong van linnen, meer bepaald in de vele vlasvelden die West-Europa rijk is. Na de oogst blijven de vlasstengels op de velden liggen, waardoor een opvallend gestreept landschap met prachtige kleurschakeringen ontstaat. Dit beeld, kenmerkend voor Doudeville en andere vlasregio’s, werd verwerkt tot een intigerend strepenpatroon.",
      fr: "Evadons-nous, direction Doudeville ! Située en France, cette commune normande est connue pour être la capitale du lin. Voilà pourquoi le design de ce revêtement mural s’inspire directement de cette plante, et plus précisément des champs de lin qui parcourent toute l’Europe de l’Ouest. Durant la récolte, seule la fibre est récupérée, coupée au niveau de la tige qui reste intacte. C’est ce qui créé ce paysage strié si caractéristique, aux magnifiques nuances de couleur. Une beauté, typique de Doudeville et des autres régions linières, inspiration de ce motif rayé qui ne laissera personne indifférent. Le motif est imprimé sur du vrai textile.",
      de: "Diese Panoramatapete, auf echtem Textil gedruckt, wurde nach der Gemeinde Doudeville in der Normandie benannt, die in Frankreich auch „Capitale du lin“, also Leinenhauptstadt, genannt wird. Das subtile Design ist von den Ursprüngen von Leinen inspiriert, genauer gesagt von den vielen Flachsfeldern, die in Westeuropa in großer Zahl vorhanden sind. Nach der Ernte bleiben die Flachsstängel auf den Feldern zurück, wodurch eine reizvolle gestreifte Landschaft mit prachtvollen Farbschattierungen entsteht. Dieser Anblick, der charakteristisch für Doudeville und andere Flachsregionen ist, wurde in einem attraktiven Streifenmuster eingefangen.",
    },
    moreInfoLink:
      "arte-international.com/en/collections/essentials-washed-linen/doudeville",
    dimensionsImage: Doudeville59590Dimensions,
    dimensions: {
      width: 390,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 390, height: 300 },
    colorVariants: [
      {
        code: 59590,
        name: "Mauve",
        url: Doudeville59590,
        downloadUrl: Doudeville59590,
        dimensionsImage: Doudeville59590Dimensions,
        downloadName: "Essentials_Washed_Linen_Doudeville_59590_Flatshot",
      },
      {
        code: 59591,
        name: "Hazel",
        url: Doudeville59591,
        downloadUrl: Doudeville59591,
        dimensionsImage: Doudeville59591Dimensions,
        downloadName: "Essentials_Washed_Linen_Doudeville_59591_Flatshot",
      },
      {
        code: 59592,
        name: "Fjord",
        url: Doudeville59592,
        downloadUrl: Doudeville59592,
        dimensionsImage: Doudeville59592Dimensions,
        downloadName: "Essentials_Washed_Linen_Doudeville_59592_Flatshot",
      },
    ],
  },
  {
    name: "Sherwood",
    scene: Sherwood59640,
    collectionName: "Essentials Brushed Suede",
    collectionCode: "24-3993",
    description: {
      en: "This panoramic is like a large painting, taking its inspiration from the beautiful Sherwood Forest in Nottinghamshire, UK. The forest is very popular among nature lovers for its many picturesque trails. The panoramic has been digitally printed on a very realistic linen texture and comes in three beautiful colour combinations.",
      nl: "Dit panorama is als een groot schilderij, geïnspireerd op het prachtige Sherwood Forest in het Britse Nottinghamshire. Bij natuurliefhebbers is dit woud gekend omwille van de vele schilderachtige bospaadjes. Het wordt digitaal geprint op een zeer realistische linnentextuur en bestaat in drie prachtige kleurencombinaties.",
      fr: "Tel un grand tableau, ce panorama s’inspire directement de la somptueuse forêt de Sherwoord, située dans le Nottinghamshire au Royaume-Uni. Une forêt connue des amoureux de la nature notamment pour ses sentiers si pittoresques. Pour obtenir ce résultat au réalisme saisissant, nous avons combiné trois superbes nuances de couleur à l'aide d'un procédé d’impression numérique sur une toile de lin.",
      de: "Diese Panoramatapete ist wie ein großes Gemälde, inspiriert von dem großartigen Sherwood Forest im britischen Nottinghamshire. Bei Naturliebhabern ist dieser Wald für seine vielen malerischen Waldwege bekannt. Es handelt sich um einen Digitaldruck auf einer sehr realistischen Leinenstruktur, erhältlich in drei prachtvollen Farbkombinationen.",
    },
    moreInfoLink:
      "arte-international.com/en/collections/essentials-brushed-suede/sherwood",
    dimensionsImage: Sherwood59640Dimensions,
    dimensions: {
      width: 204,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 204, height: 300 },
    colorVariants: [
      {
        code: 59640,
        name: "Unakite",
        url: Sherwood59640,
        downloadUrl: Sherwood59640,
        dimensionsImage: Sherwood59640Dimensions,
        downloadName: "Essentials_Brushed_Suede_Sherwood_59640_Flatshot",
      },
      {
        code: 59641,
        name: "Desert",
        url: Sherwood59641,
        downloadUrl: Sherwood59641,
        dimensionsImage: Sherwood59641Dimensions,
        downloadName: "Essentials_Brushed_Suede_Sherwood_59641_Flatshot",
      },
      {
        code: 59642,
        name: "Bister",
        url: Sherwood59642,
        downloadUrl: Sherwood59642,
        dimensionsImage: Sherwood59642Dimensions,
        downloadName: "Essentials_Brushed_Suede_Sherwood_59642_Flatshot",
      },
    ],
  },
  {
    name: "Sunburst",
    scene: SunBurst90510,
    collectionName: "Boutique",
    collectionCode: "24-3954",
    description: {
      en: "An evocative panoramic wallcovering depicting a rising, radiant sun in the sky using a very fine layer of wood veneer.",
      nl: "Een tot de verbeelding sprekende muurbekleding. Het is niet moeilijk om in dit panorama, gemaakt van een heel fijn laagje houtfineer, een rijzende zon te zien die zich stralend aan de hemel verheft.",
      fr: "La simple vue de ce revêtement mural va stimuler votre imagination. Car dans ce panorama confectionné grâce à un placage de bois ultra fin, on distingue le soleil levant qui darde ses rayons jusqu’au firmament.",
      de: "Eine Wandbekleidung, die die Fantasie anregt. Es ist nicht schwer, in diesem aus einer ganz dünnen Schicht Holzfurnier gefertigten Panorama eine aufgehende Sonne zu sehen, die sich strahlend in den Himmel erhebt.",
    },
    moreInfoLink:
      "https://www.arte-international.com/en/collections/boutique/sunburst/90510",
    dimensionsImage: SunBurst90510,
    dimensions: {
      width: 320,
      height: 330,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: false,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 90510,
        name: "Natural",
        url: SunBurst90510,
        downloadUrl: SunBurst90510,
        dimensionsImage: SunBurst90510,
        downloadName: "Boutique_Sunburst_90510",
      },
      {
        code: 90511,
        name: "Desert Sun",
        url: SunBurst90511,
        downloadUrl: SunBurst90511,
        dimensionsImage: SunBurst90511,
        downloadName: "Boutique_Sunburst_90511",
      },
    ],
  },
  {
    name: "Between Rivers",
    scene: Babylon97000,
    collectionName: "Kharga",
    collectionCode: "23-3874",
    description: {
      en: "This mythical scene is set in the lush nature of Babylonia between the Tigris and Euphrates rivers, the region where arable and livestock farming originated. The imaginative tableau shows elephants, gazelles, date palms and the remains of ancient temples. ",
      nl: "Dit mythisch tafereel speelt zich af in de weelderige natuur van Babylonia tussen de rivieren Tigris en Eufraat; de regio waar akkerbouw en veeteelt ontstonden. Het fantasierijke tableau toont olifanten, gazellen, dadelpalmen en resten van oude tempels. ",
      fr: "Dans la nature luxuriante de Babylone, entre les fleuves du Tigre et de l’Euphrate, se déroule une scène mythique, un tantinet fantaisiste. Eléphants, gazelles, palmiers dattiers et vestiges d’anciens temples sont mis en scène dans ce qui fut le berceau de l’agriculture et de l’élevage. ",
      de: "Dans la nature luxuriante de Babylone, entre les fleuves du Tigre et de l’Euphrate, se déroule une scène mythique, un tantinet fantaisiste. Eléphants, gazelles, palmiers dattiers et vestiges d’anciens temples sont mis en scène dans ce qui fut le berceau de l’agriculture et de l’élevage. ",
    },
    moreInfoLink: "arte-international.com/en/collections/kharga/zerzura/74060",
    dimensionsImage: Babylon97000,
    dimensions: {
      width: 350,
      height: 270,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: false,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 97000,
        name: "Cream",
        url: Babylon97000,
        downloadUrl: Babylon97000,
        dimensionsImage: Babylon97000,
        downloadName: "Babylon_BetweenRivers_97000",
      },
      {
        code: 97001,
        name: "Ecru",
        url: Babylon97001,
        downloadUrl: Babylon97001,
        dimensionsImage: Babylon97001,
        downloadName: "Babylon_BetweenRivers_97001",
      },
      {
        code: 97002,
        name: "Feather",
        url: Babylon97002,
        downloadUrl: Babylon97002,
        dimensionsImage: Babylon97002,
        downloadName: "Babylon_BetweenRivers_97002",
      },
      {
        code: 97003,
        name: "Rattan",
        url: Babylon97003,
        downloadUrl: Babylon97003,
        dimensionsImage: Babylon97003,
        downloadName: "Babylon_BetweenRivers_97003",
      },
    ],
  },
  {
    name: "Banyan",
    scene: Banyan11530,
    collectionName: "Alaya",
    collectionCode: "23-3886",
    description: {
      en: "This panoramic takes its name from the banyan tree, which is commonly found along the Silk Road. It lets you wander through a dreamy fantasy landscape, amid the hidden valleys and summits of the Himalayas. The fact that it has a look of pure silk truly makes the panoramic come to life.",
      nl: "Deze panoramique ontleent haar naam aan een veel voorkomende boom langs de zijderoute: de Banyan Tree. Ze laat je verdwalen in een dromerig fantasielandschap, goed verstopt hoog in de Himalaya. Het panorama komt nog meer tot leven dankzij de pure zijdelook.",
      fr: "Cette reproduction tire son nom d’un arbre sacré, omniprésent aux abords de la route de la soie : le Banian. Ainsi, bien dissimulé par les hauteurs de l’Himalaya, plongez dans un paysage onirique, sublimé par l’utilisation d’un vinyle pareil à de la soie pure.",
      de: "Diese Panorama-Wandbekleidung verdankt ihren Namen einem Baum, der entlang der Seidenstraße häufig zu finden ist: dem Banyanbaum. Tauchen Sie ein in eine verträumte Fantasielandschaft, hoch oben in den Bergen des Himalaja. Dank der reinen Seide-Optik wirkt das Panorama noch lebendiger.",
    },
    moreInfoLink: "arte-international.com/en/collections/alaya/banyan/11530",
    dimensionsImage: Banyan11530Dimensions,
    dimensions: {
      width: 480,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 11530,
        name: "Red Blossom",
        url: Banyan11530,
        downloadUrl: Banyan11530,
        dimensionsImage: Banyan11530Dimensions,
        downloadName: "Alaya_Banyan_11530_Flatshot",
      },
      {
        code: 11531,
        name: "Deep Forest",
        url: Banyan11531,
        downloadUrl: Banyan11531,
        dimensionsImage: Banyan11531Dimensions,
        downloadName: "Alaya_Banyan_11531_Flatshot",
      },
    ],
  },
  {
    name: "Ciel Tropical",
    scene: CielTropical97650,
    collectionName: "Décors & Panoramiques",
    collectionCode: "22-3834",
    description: {
      en: "Ciel Tropical shows a surreal skyline of the tropical jungle. This exuberant panoramic is made of ultra-soft velvet fabric and gives your interior an exclusive look with an abundance of luxury.",
      nl: "Ciel Tropical toont een surrealistische skyline van de tropische jungle. Dit uitbundig panorama is gemaakt van ultrazachte velvetstof en geeft je interieur een exclusieve uitstraling met overvloed aan luxe.",
      fr: "Ciel Tropical montre une ligne d'horizon surréaliste de la jungle tropicale. Ce panorama exubérant en tissu velours ultra-doux donne à votre intérieur un look exclusif avec une abondance de luxe.",
      de: "Ciel Tropical zeigt eine surrealistische Skyline des tropischen Dschungels. Dieses überschwängliche Panorama ist aus ultraweichem Samtstoff gefertigt und verleiht Ihrem Interieur eine exklusive Ausstrahlung mit einer Fülle von Luxus.",
    },
    moreInfoLink:
      "arte-international.com/en/collections/decors-panoramiques/ciel-tropical/97650",
    dimensionsImage: CielTropical97650Dimensions,
    dimensions: {
      width: 520,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 97650,
        name: "Bright Midnight",
        url: CielTropical97650,
        downloadUrl: CielTropical97650,
        dimensionsImage: CielTropical97650Dimensions,
        downloadName: "DécorsPanoramiques_CielTropical_97650_Flatshot",
      },
      {
        code: 97651,
        name: "Bright Pimento",
        url: CielTropical97651,
        downloadUrl: CielTropical97651,
        dimensionsImage: CielTropical97651Dimensions,
        downloadName: "DécorsPanoramiques_CielTropical_97651_Flatshot",
      },
      {
        code: 97652,
        name: "Emerald Forest",
        url: CielTropical97652,
        downloadUrl: CielTropical97652,
        dimensionsImage: CielTropical97652Dimensions,
        downloadName: "DécorsPanoramiques_CielTropical_97652_Flatshot",
      },
    ],
  },
  {
    name: "Hills of Sapa",
    scene: HillsOfSapa97590,
    collectionName: "Décors & Panoramiques",
    collectionCode: "22-3834",
    description: {
      en: "Oriental scene in the rice fields of Sapa. This Vietnamese region is known for its thousands of rice terraces that cover the valleys. This hand-painted scene is printed on a base with a dupion silk look.",
      nl: "Oosters tafereel in de rijstvelden van Sapa. Deze Vietnamese regio staat bekend om zijn duizenden rijstterrassen die de valleien inpalmen. Deze handgeschilderde scene is geprint op een basis met een dupion zijdelook.",
      fr: "Scène orientale dans les rizières de Sapa. Cette région vietnamienne est connue pour ses milliers de rizières en terrasses qui couvrent les vallées. Cette scène peinte à la main est imprimée sur un tissu en soie doupion.",
      de: "Eine fernöstliche Szenerie in den Reisfeldern von Sapa. Diese vietnamesische Region ist bekannt für ihre tausende Anzahl von Reisterrassen, die gesamte Täler einrahmen. Diese handgemalte Szene wurde auf Material mit einer Dupion-Seidenoptik gedruckt.",
    },
    moreInfoLink:
      "arte-international.com/en/collections/decors-panoramiques/hills-of-sapa/97590",
    dimensionsImage: HillsOfSapa97590Dimensions,
    dimensions: {
      width: 480,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 97590,
        name: "Twilight",
        url: HillsOfSapa97590,
        downloadUrl: HillsOfSapa97590,
        dimensionsImage: HillsOfSapa97590Dimensions,
        downloadName: "DécorsPanoramiques_HillsofSapa_97590_Flatshot",
      },
      {
        code: 97591,
        name: "Dawn",
        url: HillsOfSapa97591,
        downloadUrl: HillsOfSapa97591,
        dimensionsImage: HillsOfSapa97591Dimensions,
        downloadName: "DécorsPanoramiques_HillsofSapa_97591_Flatshot",
      },
    ],
  },
  {
    name: "Le Jardin Mystique",
    scene: LeJardinMystique97550,
    collectionName: "Décors & Panoramiques",
    collectionCode: "22-3834",
    description: {
      en: "An image of this hand-painted landscape shows a golden dawn against a misty forest backdrop. Be enchanted by this mysterious sunrise.",
      nl: "Een afbeelding van dit handgeschilderd landschap toont de gouden ochtendstond tegen een mistig bosdecor. Laat je betoveren door deze mysterieuze zonsopgang in een zijdelook afwerking.",
      fr: "Une image de ce paysage peint à la main illustre l'aube dorée sur fond de forêt brumeuse. Laissez-vous envoûter par ce mystérieux lever de soleil à la finition satinée.",
      de: "Eine Abbildung dieser handgemalten Landschaft zeigt die goldene Morgendämmerung vor einem nebligen Waldhintergrund. Lassen Sie sich von diesem geheimnisvollen Sonnenaufgang mit einem Finish in Seidenoptik verzaubern!",
    },
    moreInfoLink:
      "arte-international.com/en/collections/decors-panoramiques/le-jardin-mystique/97550",
    dimensionsImage: LeJardinMystique97550Dimensions,
    dimensions: {
      width: 520,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 97550,
        name: "Fantasy",
        url: LeJardinMystique97550,
        downloadUrl: LeJardinMystique97550,
        dimensionsImage: LeJardinMystique97550Dimensions,
        downloadName: "DécorsPanoramiques_Lejardinmystique_97550_Flatshot",
      },
      {
        code: 97551,
        name: "Dove",
        url: LeJardinMystique97551,
        downloadUrl: LeJardinMystique97551,
        dimensionsImage: LeJardinMystique97551Dimensions,
        downloadName: "DécorsPanoramiques_Lejardinmystique_97551_Flatshot",
      },
    ],
  },
  {
    name: "Les Mystères de Madagascar",
    scene: LesMysteresDeMadagascar97530,
    collectionName: "Décors & Panoramiques",
    collectionCode: "22-3834",
    description: {
      en: "A unique place on earth. From canyons to beautiful pearly white beaches, this mysterious island in the Indian Ocean has an endless appeal to the imagination. Different geographic and ethnic influences characterise Madagascar. This great diversity is reflected in this coarse linen-effect panorama.",
      nl: "Een unieke plaats op aarde. Van canyons tot prachtig parelwitte stranden, het mysterieuze eiland in de Indische oceaan spreekt eindeloos tot de verbeelding. De verschillende geografische en etnische invloeden kenmerken Madagascar. Deze grote diversiteit weerspiegelt zich in dit panorama met een grof linnen-effect.",
      fr: "Un endroit unique sur terre. Des canyons aux magnifiques plages de sable blanc nacré, la mystérieuse île de l'océan Indien éveille sans fin l'imagination. Madagascar doit son caractère à ses différentes influences géographiques et ethniques. Cette grande diversité se reflète dans ce panoramique à l’effet soie.",
      de: "Ein einzigartiger Ort auf der Welt. Von Schluchten bis hin zu wunderschönen perlweißen Stränden: Die geheimnisvolle Insel im Indischen Ozean regt die Fantasie unendlich an. Die unterschiedlichen geografischen und ethnischen Einflüsse haben Madagaskar geprägt. Diese große Vielfalt spiegelt sich in diesem Panorama mit Seideneffekt wider.",
    },
    moreInfoLink:
      "arte-international.com/en/collections/decors-panoramiques/les-mysteres-de-madagascar/97530",
    dimensionsImage: LesMysteresDeMadagascar97530Dimensions,
    dimensions: {
      width: 480,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 97530,
        name: "Marrakech",
        url: LesMysteresDeMadagascar97530,
        downloadUrl: LesMysteresDeMadagascar97530,
        dimensionsImage: LesMysteresDeMadagascar97530Dimensions,
        downloadName:
          "DécorsPanoramiques_LesmystèresdeMadagascar_97530_Flatshot",
      },
      {
        code: 97531,
        name: "Dune",
        url: LesMysteresDeMadagascar97531,
        downloadUrl: LesMysteresDeMadagascar97531,
        dimensionsImage: LesMysteresDeMadagascar97531Dimensions,
        downloadName:
          "DécorsPanoramiques_LesmystèresdeMadagascar_97531_Flatshot",
      },
    ],
  },
  {
    name: "Les Songes Toscans",
    scene: LesSongesToscans97520,
    collectionName: "Décors & Panoramiques",
    collectionCode: "22-3834",
    description: {
      en: "Songes depicts a Tuscan landscape in a dreamy fantasy world. This panoramic appeals to the imagination thanks to its soft shapes. The cypresses, a typical symbol of Italian nature, provide a lifting, vertical effect.",
      nl: "Songes toont een Toscaans landschap in een dromerige fantasiewereld. Dit panorama in chenille finish spreekt tot de verbeelding dankzij haar zachte vormen. De cipressen, een typerend symbool van de Italiaanse natuur, zorgen voor een liftend, verticaal effect.",
      fr: "Songes Toscans dépeint un paysage dans un monde fantastique et onirique. Les formes douces de ce panoramique doté d'une finition en chenille, éveillent l'imagination. Les cyprès, symbole typique de la nature italienne, offrent un effet vertical lissant.",
      de: "Songes zeigt eine toskanische Landschaft in einer verträumten Fantasiewelt. Dieses Panorama in Chenille regt dank seiner weichen Formen die Fantasie an. Die Zypressen, ein typisches Symbol der italienischen Natur, erzeugen einen liftenden vertikalen Effekt.",
    },
    moreInfoLink:
      "arte-international.com/en/collections/decors-panoramiques/les-songes-toscans/97520",
    dimensionsImage: LesSongesToscans97520Dimensions,
    dimensions: {
      width: 480,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 97520,
        name: "Toscany",
        url: LesSongesToscans97520,
        downloadUrl: LesSongesToscans97520,
        dimensionsImage: LesSongesToscans97520Dimensions,
        downloadName: "DécorsPanoramiques_LessongesToscans_97520_Flatshot",
      },
      {
        code: 97521,
        name: "Moorland",
        url: LesSongesToscans97521,
        downloadUrl: LesSongesToscans97521,
        dimensionsImage: LesSongesToscans97521Dimensions,
        downloadName: "DécorsPanoramiques_LessongesToscans_97521_Flatshot",
      },
    ],
  },
  {
    name: "Milk and Honey",
    scene: MilkAndHoney97630,
    collectionName: "Décors & Panoramiques",
    collectionCode: "22-3834",
    description: {
      en: "A paradise scene that makes you dream away. Hummingbirds, parrots and monkeys live harmoniously in the land of milk and honey. This panoramic is printed on a background with a parchment paper look for an unmatched vintage effect.",
      nl: "Een paradijselijk tafereel dat heerlijk doet wegdromen. Kolibries, papegaaien en aapjes leven harmonieus samen in het land van melk en honing. Dit panorama is geprint op een look van perkamentpapier voor een niet te evenaren vintage-effect.",
      fr: "Une scène paradisiaque qui fait rêver, où colibris, perroquets et singes vivent en harmonie au Pays de Cocagne. Ce panorama est imprimé sur un papier parchemineux pour un effet vintage inégalé.",
      de: "Eine paradiesische Szene, die zum Träumen anregt. Kolibris, Papageien und Affen leben in Harmonie im Land von Milch und Honig. Dieses Panorama ist auf einem Papier mit Pergament-Look gedruckt, was einen unvergleichlichen Vintage-Effekt erzeugt.",
    },
    moreInfoLink:
      "arte-international.com/en/collections/decors-panoramiques/milk-and-honey/97630",
    dimensionsImage: MilkAndHoney97630Dimensions,
    dimensions: {
      width: 300,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 97630,
        name: "Silver Pearl",
        url: MilkAndHoney97630,
        downloadUrl: MilkAndHoney97630,
        dimensionsImage: MilkAndHoney97630Dimensions,
        downloadName: "DécorsPanoramiques_MilkandHoney_97630_Flatshot",
      },
      {
        code: 97631,
        name: "Gold Storm",
        url: MilkAndHoney97631,
        downloadUrl: MilkAndHoney97631,
        dimensionsImage: MilkAndHoney97631Dimensions,
        downloadName: "DécorsPanoramiques_MilkandHoney_97631_Flatshot",
      },
    ],
  },
  {
    name: "Rovine",
    scene: Rovine42560,
    collectionName: "Sculptura",
    collectionCode: "22-3811",
    description: {
      en: "Rovine is a stately panoramic in vinyl that imitates an etching of a Greek ruin. The drawing shows antiquity in decay. The work is dedicated to Monsieur Descamps, one of the king’s artists.",
      nl: "Rovine is een statig panorama in vinyl dat een ets nabootst van een Griekse ruïne. De tekening toont de oudheid in verval. Het werk kent z’n origine bij Monsieur Descamps, een schilder van de koning.",
      fr: "Panorama majestueux s’il en est, conçu en vinyle, Rovine est la reproduction d’une gravure représentant un vestige hérité de la Grèce antique. Un revêtement mural grandiose, inspiré de l’œuvre de l’artiste Jean-Baptiste Descamps, peintre du roi Louis XV et directeur de l'Académie des arts à Rouen.",
      de: "Rovine ist eine prachtvolle Panorama-Ansicht in Vinyl, die der Radierung einer griechischen Ruine nachempfunden ist. Das Bild verweist auf das Vergehen der Antike. Das Werk ist eine Widmung an den Hofmaler Monsieur Descamps.",
    },
    moreInfoLink:
      "arte-international.com/en/collections/sculptura/rovine/42560",
    dimensionsImage: Rovine42560Dimensions,
    dimensions: {
      width: 200,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 42560,
        name: "Onyx",
        url: Rovine42560,
        downloadUrl: Rovine42560,
        dimensionsImage: Rovine42560Dimensions,
        downloadName: "Sculptura_Rovine_42560_Flatshot",
      },
      {
        code: 42561,
        name: "Denim Blue",
        url: Rovine42561,
        downloadUrl: Rovine42561,
        dimensionsImage: Rovine42561Dimensions,
        downloadName: "Sculptura_Rovine_42561_Flatshot",
      },
      {
        code: 42562,
        name: "Toffee",
        url: Rovine42562,
        downloadUrl: Rovine42562,
        dimensionsImage: Rovine42562Dimensions,
        downloadName: "Sculptura_Rovine_42562_Flatshot",
      },
    ],
  },
  {
    name: "Secret Silhouettes",
    scene: SecretSilhouettes97720,
    collectionName: "Décors & Panoramiques",
    collectionCode: "22-3834",
    description: {
      en: "Mysterious, feminine silhouettes look at you and transport you into a fantastical story. This dreamy scene with its rounded shapes appeals to the imagination, rather like a painting. The soft chenille material used suits this panoramic perfectly.",
      nl: "Mysterieuze, vrouwelijke silhouetten kijken je aan en nemen je mee in een fantasierijk verhaal. Dit dromerige tafereel met ronde vormen spreekt tot de verbeelding zoals een schilderij. Het gebruikte materiaal van zachte velours past dan ook perfect bij dit panorama.",
      fr: "Des silhouettes féminines empreintes de mystère vous observent, vous transportant dans un conte fantastique. Avec ses formes arrondies, cette scène, comme échappée d’un songe, titille votre imaginaire à la façon d’une peinture. Et le velours doux employé dans ce panoramique accentue l’atmosphère onirique.",
      de: "Geheimnisvolle weibliche Silhouetten blicken Sie an und entführen Sie in eine mystische Geschichte. Diese traumgleiche Szene mit ihren abgerundeten Formen bringt, gleich einem Gemälde, die Fantasie mit ins Spiel. Das verwendete weiche Veloursmaterial passt perfekt zur Atmosphäre dieses Panoramas.",
    },
    moreInfoLink:
      "arte-international.com/en/collections/decors-panoramiques/secret-silhouettes/97720",
    dimensionsImage: SecretSilhouettes97720Dimensions,
    dimensions: {
      width: 480,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 97720,
        name: "Saffron",
        url: SecretSilhouettes97720,
        downloadUrl: SecretSilhouettes97720,
        dimensionsImage: SecretSilhouettes97720Dimensions,
        downloadName: "DécorsPanoramiques_SecretSilhouettes_97720_Flatshot",
      },
      {
        code: 97721,
        name: "Desert Dust",
        url: SecretSilhouettes97721,
        downloadUrl: SecretSilhouettes97721,
        dimensionsImage: SecretSilhouettes97721Dimensions,
        downloadName: "DécorsPanoramiques_SecretSilhouettes_97721_Flatshot",
      },
    ],
  },
  {
    name: "Tropic",
    scene: Tropic75500,
    collectionName: "Monsoon",
    collectionCode: "22-3834",
    description: {
      en: "A print with extra large dimensions for a panoramic effect, on a subtle mosaic texture. Available in silver and gold.",
      nl: "Een print met extra grote afmetingen voor een uitgesproken panoramisch effect, op een subtiele mozaïektextuur. Verkrijgbaar in zilver en goud.",
      fr: "Un motif proposé dans de très grandes dimensions, pour un effet panoramique résolu sur une texture de mosaïque subtile. Disponible en doré et en argenté.",
      de: "Ein Motiv in extragroßen Maßen für ein auffallendes Panoramaeffekt, auf einer subtilen Mosaiktextur. Erhältlich in Silber und Gold.",
    },
    moreInfoLink:
      "https://www.arte-international.com/en/collections/monsoon/tropic/75500",
    dimensionsImage: Tropic75500Dimensions,
    dimensions: {
      width: 272,
      height: 400,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 272, height: 400 },
    colorVariants: [
      {
        code: 75500,
        name: "",
        url: Tropic75500,
        downloadUrl: Tropic75500,
        dimensionsImage: Tropic75500Dimensions,
        downloadName: "DécorsPanoramiques_Monsoon_Tropic_75500",
      },
      {
        code: 75501,
        name: "",
        url: Tropic75501,
        downloadUrl: Tropic75501,
        dimensionsImage: Tropic75501Dimensions,
        downloadName: "DécorsPanoramiques_Monsoon_Tropic_75501",
      },
    ],
  },
  {
    name: "Rêverie Tropicale",
    scene: ReverieTropicale26770,
    collectionName: "Essentials Les Naturels",
    collectionCode: "22-3834",
    description: {
      en: "A dreamy panoramic view that takes you to higher realms. Who wouldn't want to spend some time amid the lush foliage of this gorgeous oasis? The detail in this design is impressive, but upon closer inspection the subtle woven grass print in the background suddenly catches the eye, adding a natural accent to the design and the material on which it has been printed.",
      nl: "Een dromerige panoramique die je in hogere sferen brengt. Wie wil er niet een tijdje verdwalen tussen het gebladerte in deze heerlijke oase? In de weelderige tekening valt steeds weer iets nieuws te ontdekken, maar wie goed kijkt, ziet in de achtergrond ook een subtiele bedrukking van geweven gras. Zo krijgt niet alleen het dessin, maar ook het materiaal waarop het geprint is een natuurlijke uitstraling.",
      fr: "A travers l’envoûtant panorama Rêverie tropicale, évadez-vous dans des contrées lointaines. Prenez le temps de vous égarer au cœur de l’épais feuillage de cette exquise oasis. Approchez-vous. Peu à peu de nouveaux détails se dévoilent à vous. A l’image de la subtile reproduction d’herbe tissée présente en arrière-plan. Elle insuffle un aspect naturel et authentique au motif et au textile sur lequel il est imprimé.",
      de: "Ein verträumtes Panorama, bei dem Sie sich in fernen Welten wähnen. In dieser traumhaften Oase möchte sich doch jeder gerne einmal im Blattwerk verirren, oder? Es ist eine Zeichnung üppiger Natur, bei der es immer etwas Neues zu entdecken gibt, aber wenn man genauer hinschaut, erkennt man auch, wie subtil der Hintergrund aus gewebtem Gras in den Druck einbezogen wurde. Auf diese Weise erhält nicht nur das Design, sondern auch das Material, auf das es gedruckt wird, eine natürliche Optik.",
    },
    moreInfoLink:
      "https://www.arte-international.com/en/collections/essentials-les-naturels/reverie-tropicale",
    dimensionsImage: ReverieTropicale26770Dimensions,
    dimensions: {
      width: 300,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 300, height: 300 },
    colorVariants: [
      {
        code: 26770,
        name: "Jungle Green",
        url: ReverieTropicale26770,
        downloadUrl: ReverieTropicale26770,
        dimensionsImage: ReverieTropicale26770Dimensions,
        downloadName:
          "DécorsPanoramiques_EssentialsLesNaturels_ReverieTropicale_26770",
      },
      {
        code: 26771,
        name: "Golden Hour",
        url: ReverieTropicale26771,
        downloadUrl: ReverieTropicale26771,
        dimensionsImage: ReverieTropicale26771Dimensions,
        downloadName:
          "DécorsPanoramiques_EssentialsLesNaturels_ReverieTropicale_26771",
      },
    ],
  },
  {
    name: "Silk Road Garden",
    scene: SilkRoadGarden72000,
    collectionName: "Expedition",
    collectionCode: "19-3623",
    description: {
      en: "A lifelike jungle scenery as an eye-catcher in your interior. Printing on a real textile with a natural look, combined with a subtle, golden background, brings this grand panoramic decor to life. This design is available with or without a Bengal tiger in the design.",
      nl: "Een levensechte jungle scenery als eyecatcher in je interieur. De bedrukking op een echt textiel met een natuurlijke look, in combinatie met een subtiele, gouden ondergrond brengt dit groots panoramisch decor tot leven. Het is verkrijgbaar met of zonder Bengaalse tijger in het motief.",
      fr: "Une jungle plus vraie que nature, qui attirera toute l'attention dans votre intérieur. L'impression sur un textile à l'aspect naturel et l'utilisation d'une trame dorée subtile donnent vie à ce gigantesque décor panoramique. Ce motif est disponible avec ou sans tigre du Bengale.",
      de: "Eine lebensechte Dschungellandschaft als Blickfang in Ihrem Interieur. Der Druck auf echtem Textil mit natürlicher Optik, kombiniert mit einem subtilen, goldenen Hintergrund, erweckt diese großartige Panoramalandschaft zum Leben. Dieses Dekor ist mit oder ohne bengalischen Tiger im Design erhältlich.",
    },
    moreInfoLink:
      "arte-international.com/en/collections/expedition/silk-road-garden/72000",
    dimensionsImage: SilkRoadGarden72000Dimensions,
    dimensions: {
      width: 480,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 72000,
        url: SilkRoadGarden72000,
        downloadUrl: SilkRoadGarden72000,
        dimensionsImage: SilkRoadGarden72000Dimensions,
        downloadName: "Expedition_SilkRoadGarden_72000_Flatshot",
      },
      {
        code: 72001,
        url: SilkRoadGarden72001,
        downloadUrl: SilkRoadGarden72001,
        dimensionsImage: SilkRoadGarden72001Dimensions,
        downloadName: "Expedition_SilkRoadGarden_72001_Flatshot",
      },
    ],
  },
  {
    name: "Valle de Viñales",
    scene: ValleDeVinales97710,
    collectionName: "Décors & Panoramiques",
    collectionCode: "22-3834",
    description: {
      en: "This colonially inspired landscape is based on an etching. The two-tone pattern uses thick jute and a fil-à-fil technique in which the fibres used are clearly visible. The vertical lines ensure a lifting effect on the wall.",
      nl: "Dit koloniaal geïnspireerd landschap is gebaseerd op een ets. Het tweekleurig patroon maakt gebruik van dikke jute en een fil-à-fil techniek waarbij de gebruikte draden duidelijk zichtbaar zijn. De verticale lijnen zorgen voor een liftend effect op de muur.",
      fr: "Un revêtement mural tel une gravure, qui s’inscrit dans le style colonial et met en scène des palmiers au cœur d’une nature luxuriante. Composé de deux couleurs, le motif est fabriqué en jute, façonné par une prouesse technique fil-à-fil qui apporte instantanément du relief.",
      de: "Diese von den Tropen inspirierte Landschaft basiert auf einer Radierung. Das zweifarbige Muster ist aus dicker Jute aufgebaut und in Fil-à-fil-Technik, bei der die verwendeten Fasern deutlich sichtbar sind, gearbeitet. Die vertikalen Linien strecken die Wand optisch.",
    },
    moreInfoLink:
      "arte-international.com/en/collections/decors-panoramiques/valle-de-vinales/97710",
    dimensionsImage: ValleDeVinales97710Dimensions,
    dimensions: {
      width: 285,
      height: 285,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 97710,
        name: "Black Magic",
        url: ValleDeVinales97710,
        downloadUrl: ValleDeVinales97710,
        dimensionsImage: ValleDeVinales97710Dimensions,
        downloadName: "DécorsPanoramiques_ValledeVinales_97710_Flatshot",
      },
      {
        code: 97711,
        name: "Palmier",
        url: ValleDeVinales97711,
        downloadUrl: ValleDeVinales97711,
        dimensionsImage: ValleDeVinales97711Dimensions,
        downloadName: "DécorsPanoramiques_ValledeVinales_97711_Flatshot",
      },
    ],
  },
  {
    name: "Zerzura",
    scene: Zerzura74060,
    collectionName: "Kharga",
    collectionCode: "23-3874",
    description: {
      en: "The legend of Zerzura tells the story of a fertile oasis in the desert west of the Nile, of which its existence was never proven. This mythical scene is brought beautifully to life on a velvet background.",
      nl: "De legende van Zerzura vertelt het verhaal van een vruchtbare oase in de woestijn ten westen van de Nijl. Het bestaan werd echter nooit bewezen. Dit mythische tafereel komt prachtig tot haar recht op een fluwelen ondergrond.",
      fr: "Il était une fois une oasis fertile située dans le désert à l’ouest du Nil dont l’existence n’a jamais pu être prouvée. C’est la légende de Zerzura. Une scène mythique, magnifiquement représentée sur un tissu en velours.",
      de: "Die Legende von Zerzura erzählt von einer fruchtbaren Oase in der Wüste westlich des Nils; deren Existenz jedoch nie bewiesen wurde. Diese mythische Szene kommt auf einem samtenen Hintergrund wunderschön zur Geltung.",
    },
    moreInfoLink: "arte-international.com/en/collections/kharga/zerzura/74060",
    dimensionsImage: Zerzura74060Dimensions,
    dimensions: {
      width: 480,
      height: 300,
      unitOfLength: UnitOfLengths.centimeters,
    },
    supportsRepetition: true,
    previewSize: { width: 480, height: 300 },
    colorVariants: [
      {
        code: 74060,
        name: "Warm Sand",
        url: Zerzura74060,
        downloadUrl: Zerzura74060,
        dimensionsImage: Zerzura74060Dimensions,
        downloadName: "Kharga_Zerzura_74060_Flatshot",
      },
      {
        code: 74061,
        name: "Limestone Dune",
        url: Zerzura74061,
        downloadUrl: Zerzura74061,
        dimensionsImage: Zerzura74061Dimensions,
        downloadName: "Kharga_Zerzura_74061_Flatshot",
      },
      {
        code: 74062,
        name: "Desert Night",
        url: Zerzura74062,
        downloadUrl: Zerzura74062,
        dimensionsImage: Zerzura74062Dimensions,
        downloadName: "Kharga_Zerzura_74062_Flatshot",
      },
    ],
  },
];
