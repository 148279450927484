import React from "react";
import PropTypes from "prop-types";

// Components
import Switch from "react-switch";

// Helpers
import localizer from "localization/localizer";
import UnitOfLengths from "classes/UnitOfLengths";
import { capitalize } from "helpers/StringHelper";

// Styling
import styled from "styled-components";

const Layout = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const Label = styled.label`
  margin-right: 1rem;
  font-size: 1.1rem;
`;

const Option = styled.p`
  margin: 0;
  cursor: pointer;
`;

const StyledSwitch = styled(Switch).attrs((props) => ({
  onColor: "#e7e6ea",
  offColor: "#e7e6ea",
  onHandleColor: "#292929",
  offHandleColor: "#292929",
  width: 36,
  height: 12,
  handleDiameter: 18,
  uncheckedIcon: false,
  checkedIcon: false,
}))`
  margin: 0 0.5rem;
`;

/**
 * Choose between 2 units of length: meter or feet
 */
function UnitOfLengthSelector({ CurrentUnitOfLength, onUnitOfLengthChanged }) {
  return (
    <Layout>
      <Label>{capitalize(`${localizer.unitOfLength}:`)}</Label>
      <Option
        onClick={() => {
          onUnitOfLengthChanged(UnitOfLengths.meters);
        }}
      >
        {localizer.meters}
      </Option>
      <StyledSwitch
        onChange={(checked) => {
          onUnitOfLengthChanged(
            checked ? UnitOfLengths.feet : UnitOfLengths.meters
          );
        }}
        checked={CurrentUnitOfLength === UnitOfLengths.feet}
      />
      <Option
        onClick={() => {
          onUnitOfLengthChanged(UnitOfLengths.feet);
        }}
      >
        {localizer.feet}
      </Option>
    </Layout>
  );
}

UnitOfLengthSelector.propTypes = {
  /** Current unit of length that should be selected */
  CurrentUnitOfLength: PropTypes.oneOf([
    UnitOfLengths.meters,
    UnitOfLengths.feet,
  ]).isRequired,
  /**
   * Callback called when the unit of length changed
   * @callback
   * @param {UnitOfLength} unitOfLength
   */
  onUnitOfLengthChanged: PropTypes.func.isRequired,
};

export default UnitOfLengthSelector;
