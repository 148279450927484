import styled from "@react-pdf/styled-components";

export const StyledPage = styled.Page`
  width: 100%;
  height: 100vh;
  padding: 4% 5%;

  display: flex;
  flex-direction: column;

  font-family: "Europa";
`;

export const Label = styled.Text`
  font-size: 14px;
  font-style: italic;
  margin: 0 4px;
`;

export const Divider = styled.View`
  width: 100%;
  height: 0.5;

  margin: 8px 0;

  flex: 0 0 auto;

  background-color: black;
`;

export const StyledLink = styled.Link`
  color: black;
`;

export const NormalText = styled.Text`
  font-size: 10px;
  letter-spacing: 0.5;
`;
