import { ImageOfRoom } from "classes/Image";
import roomBigWindow from "resources/images/rooms/room-empty-big-window.png";

export const roomWithBigWindow = new ImageOfRoom(
  roomBigWindow,
  {
    // 800 cm of width because this is the maximal wall dimension the user can set
    width: 800,
    // The height is computed by using the ratio of the image
    height: 517,
  },
  0.08,
  0.09
);
