import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// Components
import { Button } from "./commonStyledComponents";
import Switch from "react-switch";

// Helpers
import Wall from "classes/Wall";
import localizer from "localization/localizer";
import {
  getDoorDistanceFromLeft,
  getWindowDistanceFromLeft,
  changeWallUnitOfLength,
} from "helpers/WallHelpers";
import { formatNumberArteStyle } from "helpers/NumberFormattingHelper";

// Classes
import UnitOfLengths from "classes/UnitOfLengths";

// Styling
import styled from "styled-components";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

const CanvasWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin-top: -2.5rem;
`;

const CenterCanvas = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Canvas = styled.canvas`
  border: 2px solid black;
`;

const WidthIndicator = styled.div`
  position: absolute;
  top: ${(props) => props.topOffset}px;
  left: ${(props) => props.leftOffset}px;
  // Center horizontally and vertically based on the width/height of the div
  transform: translate(-50%, 0);
`;

const HeightIndicator = styled.div`
  position: absolute;
  top: ${(props) => props.topOffset}px;
  left: ${(props) => props.leftOffset}px;
  // Center horizontally and vertically based on the width/height of the div
  transform: translate(-50%, 50%);
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
`;

const BackButton = styled(Button)`
  justify-self: start;
  font-size: 1.2rem;
`;

const NextButton = styled(Button)`
  justify-self: end;
  font-size: 1.2rem;
`;

const RepetitionControl = styled.div`
  display: flex;
  flex-flow: row;
  z-index: 10;
`;

const Option = styled.p`
  margin: 0;
  cursor: pointer;
`;

const StyledSwitch = styled(Switch).attrs((props) => ({
  onColor: "#e7e6ea",
  offColor: "#e7e6ea",
  onHandleColor: "#292929",
  offHandleColor: "#292929",
  width: 36,
  height: 12,
  handleDiameter: 18,
  uncheckedIcon: false,
  checkedIcon: false,
}))`
  margin: 0 0.5rem;
`;

// Helper functions

/**
 * @returns {HTMLCanvasElement} canvas on which the wall is rendered
 */
const getWallRenderCanvas = () => {
  return document.getElementById("canvas");
};

const WallShowcase = ({
  wall,
  onBackClicked,
  onNextClicked,
  currentPanoramique,
  currentColor,
  wallImageOffset,
  setUseRepetition,
  useRepetition,
}) => {
  const [wallInCentimeters, setWallInCentimeters] = useState();
  const [backgroundToDraw, setBackgroundToDraw] = useState();
  const [backgroundSize, setBackgroundSize] = useState();
  const [canvasSize, setCanvasSize] = useState();
  const [pixelConversion, setPixelConversion] = useState();

  // Repetition controls
  const [allowRepetition, setAllowRepetition] = useState(false);

  // Controls for dragging the background
  let currentX = useRef(0);
  let currentY = useRef(0);
  let initialX = useRef(0);
  let initialY = useRef(0);
  let xOffset = useRef(0);
  let yOffset = useRef(0);
  let isDragging = useRef(false);

  const drawRect = (ctx, x, y, width, height) => {
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.fillStyle = "white";
    ctx.fill();
    ctx.lineWidth = 2;
    ctx.strokeStyle = "black";
    ctx.closePath();
    ctx.stroke();
  };

  const drawDoors = useCallback(
    (ctx) => {
      // Draw doors
      wallInCentimeters.doors.forEach((door) => {
        const doorWidth = door.width;
        const doorHeight = door.height;
        const doorPosFromLeft = getDoorDistanceFromLeft(
          door,
          wallInCentimeters
        );

        const posX = doorPosFromLeft * pixelConversion;
        const posY = canvasSize.height - doorHeight * pixelConversion;

        const pixelWidth = doorWidth * pixelConversion;
        const pixelHeight = doorHeight * pixelConversion;

        // We add 2 to the vertical position so the bottom border of the door aligns with the border of the wall
        drawRect(ctx, posX, posY + 2, pixelWidth, pixelHeight);
      });
    },
    [canvasSize, pixelConversion, wallInCentimeters]
  );

  const drawWindows = useCallback(
    (ctx) => {
      // Draw windows
      wallInCentimeters.windows.forEach((window) => {
        const windowWidth = window.width;
        const windowHeight = window.height;
        const windowPosFromLeft = getWindowDistanceFromLeft(
          window,
          wallInCentimeters
        );
        const windowPosFromGround = window.distanceFromFloor;

        const posX = windowPosFromLeft * pixelConversion;
        const posY =
          canvasSize.height -
          windowHeight * pixelConversion -
          windowPosFromGround * pixelConversion;

        const pixelWidth = windowWidth * pixelConversion;
        const pixelHeight = windowHeight * pixelConversion;

        drawRect(ctx, posX, posY, pixelWidth, pixelHeight);
      });
    },
    [canvasSize, pixelConversion, wallInCentimeters]
  );

  const draw = useCallback(
    (x, y) => {
      const canvas = document.getElementById("canvas");
      const ctx = canvas.getContext("2d");
      ctx.strokeStyle = "red";
      ctx.lineWidth = 1;

      // Clear canvas
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      // Draw background at given location
      ctx.drawImage(
        backgroundToDraw,
        x,
        y,
        backgroundSize.width,
        backgroundSize.height
      );

      // Check for repetition
      if (useRepetition) {
        // Repeat to the left
        ctx.drawImage(
          backgroundToDraw,
          x - backgroundSize.width,
          y,
          backgroundSize.width,
          backgroundSize.height
        );

        ctx.beginPath();
        ctx.moveTo(x, y - 10);
        ctx.lineTo(x, y + backgroundSize.height + 10);
        ctx.stroke();

        // Repeat to the right
        ctx.drawImage(
          backgroundToDraw,
          x + backgroundSize.width,
          y,
          backgroundSize.width,
          backgroundSize.height
        );

        ctx.beginPath();
        ctx.moveTo(x + backgroundSize.width, y - 10);
        ctx.lineTo(x + backgroundSize.width, y + backgroundSize.height + 10);
        ctx.stroke();
      }

      drawDoors(ctx);
      drawWindows(ctx);
    },
    [backgroundToDraw, backgroundSize, drawDoors, drawWindows, useRepetition]
  );

  const onMouseDown = useCallback((e) => {
    initialX.current = e.clientX - xOffset.current;
    initialY.current = e.clientY - yOffset.current;
    isDragging.current = true;
  }, []);

  const onMouseMove = useCallback(
    (e) => {
      if (isDragging.current) {
        currentX.current = e.clientX - initialX.current;
        currentY.current = e.clientY - initialY.current;

        const xLimit = backgroundSize.width;
        const yLimit = backgroundSize.height;

        // We want to keep a part of the element visible so the user doesn't lose it in the void
        const pixelsToRemainVisible = 50;

        if (
          currentX.current > -xLimit + pixelsToRemainVisible &&
          currentX.current < canvasSize.width - pixelsToRemainVisible &&
          currentY.current > -yLimit + pixelsToRemainVisible &&
          currentY.current < canvasSize.height - pixelsToRemainVisible
        ) {
          xOffset.current = currentX.current;
          yOffset.current = currentY.current;
          draw(currentX.current, currentY.current);
        } else if (
          currentX.current > -xLimit + pixelsToRemainVisible &&
          currentX.current < canvasSize.width - pixelsToRemainVisible
        ) {
          xOffset.current = currentX.current;
          draw(currentX.current, yOffset.current);
        } else if (
          currentY.current > -yLimit + pixelsToRemainVisible &&
          currentY.current < canvasSize.height - pixelsToRemainVisible
        ) {
          yOffset.current = currentY.current;
          draw(xOffset.current, currentY.current);
        }
      }
    },
    [backgroundSize, canvasSize, draw]
  );

  const onMouseUp = useCallback((e) => {
    initialX.current = currentX.current;
    initialY.current = currentY.current;

    isDragging.current = false;
  }, []);

  useEffect(() => {
    if (backgroundToDraw && backgroundSize && canvasSize) {
      setTimeout(() => {
        let x = 0;
        let y = 0;
        if (wallImageOffset) {
          x = wallImageOffset.xOffset * pixelConversion;
          y = wallImageOffset.yOffset * pixelConversion;
          xOffset.current = x;
          yOffset.current = y;
          initialX.current = x;
          initialY.current = y;
        }

        draw(x, y);
      }, 100);
    }
  }, [
    backgroundToDraw,
    backgroundSize,
    canvasSize,
    wallImageOffset,
    pixelConversion,
    draw,
  ]);

  useEffect(() => {
    if (canvasSize) {
      const canvas = document.getElementById("canvas");

      // Clone the element to remove all the event listeners
      let newClone = canvas.cloneNode();
      newClone.width = canvasSize.width;
      newClone.height = canvasSize.height;

      newClone.addEventListener("mousedown", onMouseDown, false);
      newClone.addEventListener("mouseup", onMouseUp, false);
      newClone.addEventListener("mousemove", onMouseMove, false);

      document.getElementById("parent").replaceChild(newClone, canvas);
    }
  }, [canvasSize, onMouseDown, onMouseUp, onMouseMove]);

  useEffect(() => {
    const wallInCm = changeWallUnitOfLength(wall, UnitOfLengths.centimeters);
    setWallInCentimeters(wallInCm);

    let dimensions = currentPanoramique.dimensions;

    // Calculate wall aspect ratio
    const aspectRatio = wallInCm.width / wallInCm.height;

    // Canvas size (draw field) or wall size in pixels.
    // 500, and 1000 are just some numbers that result in a decent sized wall on the screen
    const canvasWidth = Math.min(aspectRatio * 500, 1000);
    const canvasHeight = canvasWidth / aspectRatio;
    setCanvasSize({ width: canvasWidth, height: canvasHeight });

    // Scale the background image.
    let scaleX;
    let scaleY;

    // 900 & 540 is the size of the wall image in cm this is the same for all the walls, so we see how much bigger or smaller the wall is than the image
    scaleX = dimensions.width / wallInCm.width;
    scaleY = dimensions.height / wallInCm.height;

    setPixelConversion(canvasWidth / wallInCm.width);

    const imagePromise = new Promise((resolve) => {
      const background = new Image();

      background.src = currentColor.url;

      background.onload = () => {
        resolve(background);
      };
    });

    imagePromise.then((image) => {
      let backgroundWidth = canvasWidth * scaleX;
      let backgroundHeight = canvasHeight * scaleY;

      setBackgroundSize({ width: backgroundWidth, height: backgroundHeight });
      setBackgroundToDraw(image);
    });
  }, [wall, currentPanoramique, currentColor]);

  // Check if we need to allow repetition
  useEffect(() => {
    if (wallInCentimeters && currentPanoramique) {
      let dimensions = currentPanoramique.dimensions;

      if (
        dimensions.width < wallInCentimeters.width &&
        currentPanoramique.supportsRepetition
      ) {
        setAllowRepetition(true);
      }
    }
  }, [wallInCentimeters, currentPanoramique]);

  const borderAroundCanvas = { x: 80, y: 60 }; // px
  let widthCanvasWithBorder = 0;
  let heightCanvasWithBorder = 0;

  if (canvasSize) {
    widthCanvasWithBorder = canvasSize.width + 2 * borderAroundCanvas.x;
    heightCanvasWithBorder = canvasSize.height + 2 * borderAroundCanvas.y;
  }

  return (
    <>
      {canvasSize && (
        <MainWrapper>
          {allowRepetition && (
            <RepetitionControl>
              <Option
                onClick={() => {
                  setUseRepetition(false);
                }}
              >
                {localizer.noRepetition}
              </Option>
              <StyledSwitch
                onChange={(checked) => {
                  setUseRepetition(checked);
                }}
                checked={useRepetition}
              />
              <Option
                onClick={() => {
                  setUseRepetition(true);
                }}
              >
                {localizer.allowRepetition}
              </Option>
            </RepetitionControl>
          )}

          <CanvasWrapper
            width={widthCanvasWithBorder}
            height={heightCanvasWithBorder}
          >
            <WidthIndicator
              topOffset={heightCanvasWithBorder - borderAroundCanvas.x / 2}
              leftOffset={widthCanvasWithBorder / 2}
            >
              {`${formatNumberArteStyle(wall.width, wall.unitOfLength)}`}
            </WidthIndicator>
            <HeightIndicator
              topOffset={heightCanvasWithBorder / 2}
              leftOffset={borderAroundCanvas.x / 2}
            >
              {`${formatNumberArteStyle(wall.height, wall.unitOfLength)}`}
            </HeightIndicator>
            <CenterCanvas id="parent">
              <Canvas
                id="canvas"
                width={`${canvasSize.width}px`}
                height={`${canvasSize.height}px`}
              />
            </CenterCanvas>
          </CanvasWrapper>
          <ButtonWrapper>
            <BackButton
              onClick={() => {
                if (onBackClicked) {
                  onBackClicked();
                }
              }}
            >
              {localizer.back}
            </BackButton>
            <NextButton
              onClick={() => {
                if (onNextClicked)
                  onNextClicked(
                    getWallRenderCanvas().toDataURL("image/png", 1.0),
                    {
                      xOffset: xOffset.current / pixelConversion,
                      yOffset: yOffset.current / pixelConversion,
                    }
                  );
              }}
            >
              {localizer.next}
            </NextButton>
          </ButtonWrapper>
        </MainWrapper>
      )}
    </>
  );
};

WallShowcase.propTypes = {
  /** Wall to be filled with tiles */
  wall: PropTypes.instanceOf(Wall).isRequired,
  /**
   * Callback when the user confirmed that he's fine with the wall dimensions as they are shown in the showcase
   * @callback
   * @param {string} wallImageUrl the url of the image of the wall with doors and windows
   * @param {Object} imageOffset the offset of the image in cm to the 0,0 top left origin of the canvas
   */
  onNextClicked: PropTypes.func,
  /** The back button was clicked */
  onBackClicked: PropTypes.func,
};

export default WallShowcase;
