// Helpers
import { Field } from "formik";

// Styling
import styled from "styled-components";

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: normal;

  margin: 0.5rem 0;

  &::first-letter {
    text-transform: uppercase;
  }
`;

/**
 * Title shown before a list of form fields
 */
export const FormFieldsTitle = styled(Title)`
  margin-top: 0;
  margin-bottom: 24px;
`;

export const LabelAndField = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormikField = styled(Field)`
  font-family: inherit;
  font-size: 1.125rem;
  padding: 0.625rem;
  border: 1px solid black;
  border-radius: 3px;
`;

export const FormikFieldLabel = styled.label`
  font-size: 1rem;
  color: gray;

  margin-bottom: 0.5rem;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Button = styled.button`
  padding: 0.5rem 1.5rem;

  font-family: inherit;
  font-size: 1.5rem;
  text-decoration: underline;

  border: none;
  background-color: transparent;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const SubmitButton = styled.button`
  border: 1px solid white;
  outline: none;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background: none;
  color: white;
  cursor: pointer;
`;
