import React, { useState } from "react";

// Styling
import { motion } from "framer-motion";

/**
 * Image component to render any image on the screen, it will only display the image when it is fully loaded
 */
const IdealImage = ({ src, width, height, className }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <motion.div
      className={className}
      initial={{ opacity: 0 }}
      animate={imageLoaded ? { opacity: 1 } : { opacity: 0 }}
    >
      <img
        alt=""
        src={src}
        width={width}
        height={height}
        onLoad={() => {
          setImageLoaded(true);
        }}
      />
    </motion.div>
  );
};

export default IdealImage;
