/** Check if we are in production */
export const isProduction = () => {
  console.log(process.env);
  console.log(process.env.REACT_APP_ENV);
  if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "production") {
    return true;
  }
  return false;
};

export const isStaging = () => {
  if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "staging") {
    return true;
  }
  return false;
};
