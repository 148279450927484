import LocalizedStrings from "react-localization";

import enStrings from "./en/en-EN-strings.json";
import nlStrings from "./nl/nl-NL-strings.json";
import frStrings from "./fr/fr-FR-strings.json";
import deStrings from "./de/de-DE-strings.json";

// Merge two json files into a single strings file
const languagesFiles = {
  en: enStrings,
  nl: nlStrings,
  fr: frStrings,
  de: deStrings,
};

export const supportedLanguages = ["nl", "en", "fr", "de"];

const strings = new LocalizedStrings(languagesFiles);

export const setLanguage = (lang) => {
  strings.setLanguage(lang);
};

export const getCurrentLanguage = () => {
  return strings.getLanguage();
};

export default strings;
