import React from "react";
import PropTypes from "prop-types";

// Components
import {
  FormikField,
  FormikFieldLabel,
  LabelAndField,
} from "components/commonStyledComponents";

// Helpers
import { useField } from "formik";

// Helper functions

/**
 * Implicit form submission is when the Enter key is pressed on an input of the form and that causes the form to be submitted
 * This function prevents this default behavior
 * @param {KeyboardEvent} keyboardEvent
 */
const cancelImplicitFormSubmission = (keyboardEvent) => {
  if (keyboardEvent.key === "Enter") {
    keyboardEvent.preventDefault();
  }
};

/**
 * Number input field to use in a Formik form.
 * It will render a <Field /> from Formik (https://formik.org/docs/api/field)
 * Any props that applies to a input[type=number] can be passed to this component
 */
const NumberField = (props) => {
  const { fieldName, label, className, ...remainingProps } = props;

  const [numberField, ,] = useField({ name: fieldName });

  return (
    <LabelAndField className={className}>
      {label && (
        <FormikFieldLabel htmlFor={fieldName}>{label}</FormikFieldLabel>
      )}
      <FormikField
        id={fieldName}
        {...numberField}
        type="number"
        {...remainingProps}
        // In general, we want to submit the form by default when enter is pressed, but after discussions,
        // we decided it is better to avoid it in this case. Mainly because it happened quite a few times that we submitted the form by accident
        onKeyDown={cancelImplicitFormSubmission}
      />
    </LabelAndField>
  );
};

NumberField.propTypes = {
  /** Unique value that identifies the field in a formik form */
  fieldName: PropTypes.string.isRequired,
  /** Label explaining what the number is */
  label: PropTypes.string.isRequired,
};

export default NumberField;
