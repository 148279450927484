import { ImageOfFurniture } from "classes/Image";

import furnitureStyle01ImageSrc from "resources/images/furniture/furniture-style01-300x130.png";
import furnitureStyle02ImageSrc from "resources/images/furniture/furniture-style02-304x88.png";
import furnitureStyle03ImageSrc from "resources/images/furniture/furniture-style03-307x152.png";
import furnitureStyle04ImageSrc from "resources/images/furniture/furniture-style04-100x80.png";
import furnitureStyle05ImageSrc from "resources/images/furniture/furniture-style05-275x164.png";

const leatherSofaTechnoLamp = new ImageOfFurniture(
  furnitureStyle01ImageSrc,
  { width: 348.8, height: 170.39 },
  { startRatio: 0.09, endRatio: 0.95 },
  0.32
);

const WoodenChairAndTable = new ImageOfFurniture(
  furnitureStyle02ImageSrc,
  { width: 347.35, height: 162.96 },
  { startRatio: 0.06, endRatio: 0.93 },
  0.32
);

const LoungerWithCactus = new ImageOfFurniture(
  furnitureStyle03ImageSrc,
  { width: 345.24, height: 232.84 },
  { startRatio: 0.06, endRatio: 0.95 },
  0.24
);

const DoubleChair = new ImageOfFurniture(
  furnitureStyle04ImageSrc,
  { width: 208.31, height: 173.86 },
  { startRatio: 0.33, endRatio: 0.81 },
  0.35
);

const BlackFauteuilWithPixarLamp = new ImageOfFurniture(
  furnitureStyle05ImageSrc,
  { width: 319.9, height: 225.7 },
  { startRatio: 0.06, endRatio: 0.92 },
  0.26
);

/**
 * Get the furniture that matches the tile best
 * This matching was done by our interior designer
 * @param {Number} tileCode
 * @returns {ImageOfFurniture} Image of the furniture
 */
export const getFurnitureForTileCode = (tileCode) => {
  switch (tileCode) {
    // SunBurst
    case 90510:
      return WoodenChairAndTable;
    case 90511:
      return LoungerWithCactus;

    // Babylong
    case 97000:
      return LoungerWithCactus;
    case 97001:
      return LoungerWithCactus;
    case 97002:
      return LoungerWithCactus;
    case 97003:
      return LoungerWithCactus;

    // Banyan
    case 11530:
      return DoubleChair;
    case 11531:
      return leatherSofaTechnoLamp;

    // Ciel Tropical
    case 97650:
      return LoungerWithCactus;
    case 97651:
      return LoungerWithCactus;
    case 97652:
      return LoungerWithCactus;

    // Hills of Sapa
    case 97590:
      return BlackFauteuilWithPixarLamp;
    case 97591:
      return BlackFauteuilWithPixarLamp;

    // Le Jardin Mystique
    case 97550:
      return BlackFauteuilWithPixarLamp;
    case 97551:
      return BlackFauteuilWithPixarLamp;

    // Les Mystères de Madagascar
    case 97530:
      return BlackFauteuilWithPixarLamp;
    case 97531:
      return BlackFauteuilWithPixarLamp;

    // Les Songes Toscans
    case 97520:
      return BlackFauteuilWithPixarLamp;
    case 97521:
      return BlackFauteuilWithPixarLamp;

    // Milk and Honey
    case 97630:
      return BlackFauteuilWithPixarLamp;
    case 97631:
      return WoodenChairAndTable;

    // Milk and Honey
    case 26770:
      return BlackFauteuilWithPixarLamp;
    case 26771:
      return WoodenChairAndTable;

    // Rovine
    case 42560:
      return BlackFauteuilWithPixarLamp;
    case 42561:
      return BlackFauteuilWithPixarLamp;
    case 42562:
      return BlackFauteuilWithPixarLamp;

    // Secret Silhouettes
    case 97720:
      return BlackFauteuilWithPixarLamp;
    case 97721:
      return WoodenChairAndTable;

    // Silk Road Garden
    case 72000:
      return BlackFauteuilWithPixarLamp;
    case 72001:
      return WoodenChairAndTable;

    // Valle de Viñales
    case 97710:
      return BlackFauteuilWithPixarLamp;
    case 97711:
      return WoodenChairAndTable;

    // Valle de Viñales
    case 75500:
      return BlackFauteuilWithPixarLamp;
    case 75501:
      return WoodenChairAndTable;

    // Zerzura
    case 74060:
      return BlackFauteuilWithPixarLamp;
    case 74061:
      return WoodenChairAndTable;
    case 74062:
      return BlackFauteuilWithPixarLamp;

       // Doudeville
    case 59590:
      return leatherSofaTechnoLamp;
    case 59591:
      return leatherSofaTechnoLamp;
    case 59592:
      return leatherSofaTechnoLamp;

         // Sherwood
    case 59640:
      return leatherSofaTechnoLamp;
    case 59641:
      return leatherSofaTechnoLamp;
    case 59642:
      return leatherSofaTechnoLamp;

    default:
      return undefined;
  }
};
