import UnitOfLengths from "classes/UnitOfLengths";

// Constants
const localeWithDotSeparator = "en-CA";
const localeWithCommaSeparator = "fr-CA";

/**
 * Format a number in the way prefered by Arte
 * @param {Number} number an integer or floating number that we want to format
 * @param {UnitOfLengths} unitOfLength the unit of length in which the number is, this does impact the format of the number
 * @returns {String} string with the number formatted properly followed by the unit of length
 */
export const formatNumberArteStyle = (number, unitOfLength) => {
  // The formatting that has been asked by Arte is:
  // If the number is an integer, don't put any decimals for it. Example: if number is 25 with unit of length "m", the result should be "25 m".
  // If the number is a float, always put 2 decimals. Example: if number is 1.5 with unit of length "m", the result should be "1.50 m".
  // If the number is in imperial unit of length, it should use "," for decimals.
  // If the number is in metric unit of length, it should use "." for decimals.

  let numberAsString = "";
  if (Number.isInteger(number)) {
    numberAsString = `${number}`;
  } else {
    let locale = "";
    const usesImperial = unitOfLength === UnitOfLengths.feet;
    if (usesImperial) {
      locale = localeWithCommaSeparator;
    } else {
      locale = localeWithDotSeparator;
    }

    numberAsString = number.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return `${numberAsString} ${unitOfLength}`;
};
