/**
 * Image with content of which we know the real world dimensions
 * For example, it could be an image of a door and we know that this door is 1 m x 2 m in the real world.
 */
export class ImageOfMeasuredContent {
  /**
   * @param {string} imageSrc url to the image file
   * @param {{width: number, height: number}} realWordDimensionsCm dimensions of the content of the image in the real world (in cm)
   */
  constructor(imageSrc, realWordDimensionsCm) {
    this.imageSrc = imageSrc;
    this.realWorldDimensionsCm = realWordDimensionsCm;
  }

  getHeightOnWidthRatio() {
    return this.realWorldDimensionsCm.height / this.realWorldDimensionsCm.width;
  }

  /**
   * @param {number} pixelPerCm
   * @returns {{width: number, height: number}}
   */
  getDimensionsInPixels(pixelPerCm) {
    return {
      width: this.realWorldDimensionsCm.width * pixelPerCm,
      height: this.realWorldDimensionsCm.height * pixelPerCm,
    };
  }
}

export class ImageOfRoom extends ImageOfMeasuredContent {
  /**
   *
   * @param {string} imageSrc url to the image file
   * @param {{width: number, height: number}} realWordDimensionsCm dimensions of the content of the image in the real world (in cm)
   * @param {number} floorHeightRatio where the line of the floor is located on the picture of the room.
   * @param {number} wallStartHeightRatio where the wall start is located in the picture of the room (this should be pretty close to floorHeightRatio, but accounts for the baseboard)
   */
  constructor(
    imageSrc,
    realWordDimensionsCm,
    floorHeightRatio,
    wallStartHeightRatio
  ) {
    super(imageSrc, realWordDimensionsCm);
    this.floorHeightRatio = floorHeightRatio;
    this.wallStartHeightRatio = wallStartHeightRatio;
  }

  /**
   * Get the height (where 0 is the bottom of the image), where the floor finishes in the image
   * The floor height doesn't contain the baseboard
   */
  getFloorHeightInCm() {
    return this.floorHeightRatio * this.realWorldDimensionsCm.height;
  }

  /**
   * Get the height (where 0 is the bottom of the image), where the wall starts on the image
   * The wall starts above the flooring + baseboard
   */
  getWallStartHeightInCm() {
    return this.wallStartHeightRatio * this.realWorldDimensionsCm.height;
  }
}

/**
 * Image containing a furniture which the real world dimensions are known
 */
export class ImageOfFurniture extends ImageOfMeasuredContent {
  /**
   *
   * @param {string} imageSrc url to the image file
   * @param {{width: number, height: number}} realWordDimensionsCm dimensions of the content of the image in the real world (in cm)
   * @param {{startRatio: number, endRatio: number}} furnitureHorizontalPosition where the furniture is located in the image. It is defined by ratios (values betwee 0 and 1)
   * @param {{floorHeightRatio: number}} floorHeightRatio where the line of the floor is located on the picture of the furniture. It helps define where the furniture should be placed in relation to the floor.
   */
  constructor(
    imageSrc,
    realWordDimensionsCm,
    furnitureHorizontalPosition,
    floorHeightRatio
  ) {
    super(imageSrc, realWordDimensionsCm);
    this.furnitureHorizontalPosition = furnitureHorizontalPosition;
    this.floorHeightRatio = floorHeightRatio;
  }

  /**
   * Get the width of the furniture in the image in pixels.
   * This is useful because the furniture doesn't necessarily fit perfectly in the image, so this is a way to know the width of ONLY the furniture without the rest.
   * @param {number} pixelPerCm
   */
  getFurnitureWidthInPixels(pixelPerCm) {
    const wholeImageDimensionsPx = this.getDimensionsInPixels(pixelPerCm);

    const furnitureStartPx =
      this.furnitureHorizontalPosition.startRatio *
      wholeImageDimensionsPx.width;

    const furnitureEndPx =
      this.furnitureHorizontalPosition.endRatio * wholeImageDimensionsPx.width;

    return furnitureEndPx - furnitureStartPx;
  }
}
