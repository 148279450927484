import React from "react";

import localizer from "localization/localizer";

// Pictures
import arteLogo from "resources/logos/arte-logo-without-copyright.png";

import styled from "styled-components";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 80%;
  margin-bottom: 5%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  text-align: center;
`;

const MobileDisabledPage = () => {
  return (
    <Wrapper>
      <Logo
        src={arteLogo}
        onClick={() => {
          window.open("https://www.arte-international.com/", "_blank");
        }}
      />
      <TextWrapper>
        <div>{localizer.noMobileSupportTitle}</div>
      </TextWrapper>
    </Wrapper>
  );
};

export default MobileDisabledPage;
