class Door {
  /**
   * @param {number} width width of the door
   * @param {number} height height of the door
   * @param {number} distanceFromRight distance from the right of the wall the door is placed on
   */
  constructor(width, height, distanceFromRight) {
    this.width = width;
    this.height = height;
    this.distanceFromRight = distanceFromRight;
  }
}

export default Door;
