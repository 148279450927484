/**
 * Enum for unit of lengths
 * The string values are the abbreviations of the unit in lower case letters.
 * @enum {string}
 */
const UnitOfLengths = {
  meters: "m",
  feet: "ft",
  centimeters: "cm",
  millimeters: "mm",
  inches: "inch",
};

export default UnitOfLengths;
