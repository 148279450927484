import React from "react";
import PropTypes from "prop-types";

// Components
import LanguagePicker from "components/LanguagePicker";

// Helpers
import { getCurrentLanguage } from "localization/localizer";

// Pictures
import arteLogo from "resources/logos/arte-logo-without-copyright.png";

// Styling
import styled from "styled-components";

const MainMenuWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;

  height: 4rem;
  margin: 1.25rem 10vw;
`;

const NavigationLinksLayout = styled.nav`
  flex-grow: 1;

  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
`;

const NavigationButton = styled.button.attrs((props) => ({
  disabled: !props.enabled,
}))`
  display: flex;
  justify-content: center;
  align-items: center;

  background: none;
  border: none;

  font-size: 1.06rem;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  opacity: ${(props) => (props.enabled ? "1.0" : "0.3")};

  cursor: ${(props) => (props.enabled ? "pointer" : "auto")};

  transition: all 100ms;

  padding: 10px;
`;

const Logo = styled.img`
  height: 20px;
  cursor: pointer;
`;

/**
 * List of navigation buttons representing the places the user can go to. It also indicates where the user currently is.
 * It also allows the user to select the current language of the website.
 * @param {{buttons: {key: String, enabled: Boolean, active: Boolean, onClick: Function}[], onLanguageChanged: Function}} props
 */
const NavigationBar = ({ buttons, onLanguageChanged }) => {
  const currentLanguageInLocalizer = getCurrentLanguage();
  return (
    <MainMenuWrapper>
      <Logo
        src={arteLogo}
        onClick={() => {
          window.open("https://www.arte-international.com/", "_blank");
        }}
      />
      <NavigationLinksLayout>
        {buttons.map((button) => {
          return (
            <NavigationButton
              key={button.key}
              enabled={button.enabled}
              active={button.active}
              onClick={() => {
                if (button.enabled) {
                  button.onClick();
                }
              }}
              onMouseDown={(event) => {
                // Don't focus the navigation buttons when clicked
                // because we don't think the user will want to interact with it again once he changed page
                event.preventDefault();
              }}
            >
              {button.text}
            </NavigationButton>
          );
        })}
      </NavigationLinksLayout>
      <LanguagePicker
        currentLanguage={currentLanguageInLocalizer}
        onLanguageChanged={onLanguageChanged}
      />
    </MainMenuWrapper>
  );
};

NavigationBar.propTypes = {
  /** Navigation buttons to move around the different parts of the website */
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      active: PropTypes.bool.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  /**
   * Called when a different language has been picked by the user
   * @callback
   * @param {string} language abbreviation of the language picked by user. For example: "nl", "en", etc.
   */
  onLanguageChanged: PropTypes.func,
};

export default NavigationBar;
