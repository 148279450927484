import React, { useState } from "react";

// Helpers
import { motion } from "framer-motion";
import { panoramiques } from "helpers/PanoramiqueConfig";

// Styling
import styled from "styled-components";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  column-gap: 50px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  row-gap: 50px;
  overflow-x: hidden;
`;

const Option = styled(motion.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
  flex-basis: 25%;
`;

const ScenePreviewWrapper = styled(motion.div)`
  position: relative;
  cursor: pointer;
  width: 480px;
  max-width: 480px;
  height: 300px;
  max-height: 300px;
  overflow: hidden;
`;

const ScenePreview = styled.img`
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
  transition: width 1s ease, height 1s ease;
`;

const Name = styled.div`
  margin-top: 15px;
  font-size: 18px;
`;

const previewMotion = {
  rest: {
    scale: 1,
    transition: { duration: 0.1 },
  },
  hover: {
    scale: 1.02,
    transition: { duration: 0.1 },
  },
};

const PanoramiquePage = ({ onPanoramiqueSpecified }) => {
  const [hoveredPanoramique, setHoveredPanoramique] = useState();

  const previews = panoramiques.map((option, index) => {
    return (
      <Option
        key={index}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75, delay: index * 0.2 }}
      >
        <ScenePreviewWrapper
          initial="rest"
          whileHover="hover"
          animate="rest"
          variants={previewMotion}
          onHoverStart={() => {
            setHoveredPanoramique(option);
          }}
          onHoverEnd={() => {
            setHoveredPanoramique(null);
          }}
          onClick={() => {
            onPanoramiqueSpecified(option);
          }}
        >
          <ScenePreview
            src={option.scene}
            height={hoveredPanoramique === option ? "105%" : "100%"}
          />
        </ScenePreviewWrapper>
        <Name>{option.name}</Name>
      </Option>
    );
  });

  return (
    <MainWrapper>
      <OptionsWrapper>{previews}</OptionsWrapper>
    </MainWrapper>
  );
};

export default PanoramiquePage;
