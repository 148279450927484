/**
 * Get the image whose sides are the closest to match the provided ratio
 * @param {number} heightOnWidthRatio the ratio obtained by dividing the height of door by it's width
 * @param {ImageOfMeasuredContent[]} imagesOptions the options to compare in order to find the best match
 * @returns {ImageOfMeasuredContent}
 */
export const getMatchingImage = (heightOnWidthRatio, imagesOptions) => {
  let bestMatchImage = "";
  let minRatioDifference = Number.MAX_VALUE;

  imagesOptions.forEach((image) => {
    const currentRatioDifference = Math.abs(
      image.getHeightOnWidthRatio() - heightOnWidthRatio
    );
    if (currentRatioDifference < minRatioDifference) {
      bestMatchImage = image;
      minRatioDifference = currentRatioDifference;
    }
  });

  return bestMatchImage;
};
