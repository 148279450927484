/**
 * Appends the correct unit of length at the end of the passed string.
 * For example, if the string is "2.15" and the unitOfLength is UnitOfLengths.meters. The result will be 2.15 (m)
 * @param {string} text
 * @param {UnitOfLengths} unitOfLength
 * @param {boolean} withParenthesis if the unit should be between parenthesis or not. Example with parenthesis: "Width (m)". Example without: "2m".
 */
export const appendUnit = (text, unitOfLength, withParenthesis = true) => {
  if (withParenthesis) {
    return `${text} (${unitOfLength})`;
  } else {
    return `${text}${unitOfLength}`;
  }
};

// Capitalize the first letter of a string
export const capitalize = (string) =>
  string && `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
