// Limits that the user has when building a wall with door and windows
// All constraints are by default in meters

export const wallWidthLimits = {
  min: 1,
  max: 8,
};

export const wallHeightLimits = {
  min: 0.5,
  max: 4,
};

const minDoorDistanceFromEdge = 0.5; // m

export const doorWidthLimits = {
  min: 0.5,
  max: 2,
};

export const doorHeightLimits = {
  min: 0.5,
  max: 3,
};

export const doorDistanceFromRightLimits = {
  min: minDoorDistanceFromEdge,
  max: wallWidthLimits.max - 2 * minDoorDistanceFromEdge,
};

const minWindowDistanceFromEdge = 0.5; // m

export const windowWidthLimits = {
  min: 0.2,
  max: 6,
};

export const windowDistanceFromRightLimits = {
  min: minWindowDistanceFromEdge,
  max: wallWidthLimits.max - 2 * minWindowDistanceFromEdge,
};

export const windowDistanceFromFloorLimits = {
  min: 0.1,
  max: wallHeightLimits.max,
};

export const windowHeightLimits = {
  min: 0.2,
  max: 3.5,
};

export const amountLimits = {
  doors: 3,
  windows: 4,
};
