class Window {
  /**
   *
   * @param {number} width width of the window
   * @param {number} height height of the window
   * @param {number} distanceFromRight distance from the right of the wall the window is placed on
   * @param {number} distanceFromFloor distance from the bottom of the wall (a.k.a floor) the window is placed on
   */
  constructor(width, height, distanceFromRight, distanceFromFloor) {
    this.width = width;
    this.height = height;
    this.distanceFromRight = distanceFromRight;
    this.distanceFromFloor = distanceFromFloor;
  }
}

export default Window;
