import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// Components
import SvgDropdownArrow from "DropdownArrow";

// Helpers
import { supportedLanguages } from "localization/localizer";

// Styling
import styled from "styled-components";
import { motion } from "framer-motion";

const LanguageMenuContainer = styled(motion.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const LanguageMenuHeader = styled(motion.div)`
  display: flex;
  flex-flow: row;
  align-items: center;
  cursor: pointer;
`;

const LanguageMenuList = styled(motion.ul)`
  position: absolute;
  margin-top: 30px;
  margin-right: 20px;
  background: white;
  border: 1px solid rgb(81, 79, 90);
  list-style-type: none;
  list-style-position: outside;
  z-index: 500;
  padding: 0;
`;

const LanguageItem = styled(motion.li)`
  padding: 15px;
  text-align: center;
  background: ${(props) =>
    props.selected
      ? "rgb(81, 79, 90)"
      : props.hovered
      ? "rgba(81, 79, 90,0.1)"
      : "white"};
  color: ${(props) => (props.selected ? "white" : "black")};
  user-select: none;
  cursor: pointer;
`;

const CurrentLanguageIndicator = styled.div`
  user-select: none;
`;

/**
 * A dropdown menu to pick between different languages
 * @param {{currentLanguage: String, onLanguageChanged: Function}} props
 */
const LanguagePicker = ({ currentLanguage, onLanguageChanged }) => {
  const [languageMenuOpened, setLanguageMenuOpened] = useState(false);
  const [hoveredLanguage, setHoveredLanguage] = useState();

  let unhoverTimer = useRef();

  return (
    <LanguageMenuContainer>
      <LanguageMenuHeader
        onClick={() => {
          setLanguageMenuOpened((current) => !current);
        }}
        onHoverStart={() => {
          clearTimeout(unhoverTimer.current);
        }}
        onHoverEnd={() => {
          unhoverTimer.current = setTimeout(() => {
            setLanguageMenuOpened(false);
          }, 500);
        }}
      >
        <CurrentLanguageIndicator>
          {currentLanguage.toUpperCase()}
        </CurrentLanguageIndicator>
        <SvgDropdownArrow />
      </LanguageMenuHeader>
      {languageMenuOpened && (
        <LanguageMenuList
          onHoverStart={() => {
            clearTimeout(unhoverTimer.current);
          }}
          onHoverEnd={() => {
            unhoverTimer.current = setTimeout(() => {
              setLanguageMenuOpened(false);
              setHoveredLanguage(null);
            }, 500);
          }}
        >
          {supportedLanguages.map((language) => {
            return (
              <LanguageItem
                onHoverStart={() => {
                  setHoveredLanguage(language);
                }}
                key={language}
                selected={currentLanguage === language}
                hovered={hoveredLanguage === language}
                onClick={() => {
                  onLanguageChanged(language);
                  setLanguageMenuOpened(false);
                  setHoveredLanguage(null);
                }}
              >
                {language.toUpperCase()}
              </LanguageItem>
            );
          })}
        </LanguageMenuList>
      )}
    </LanguageMenuContainer>
  );
};

LanguagePicker.propTypes = {
  /** The current language to show in the picker */
  currentLanguage: PropTypes.string.isRequired,
  /**
   * Called when a different language has been picked by the user
   * @callback
   * @param {string} language abbreviation of the language picked by user. For example: "nl", "en", etc.
   */
  onLanguageChanged: PropTypes.func,
};

export default LanguagePicker;
