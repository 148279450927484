import Wall from "classes/Wall";
import Window from "classes/Window";
import Door from "classes/Door";
import UnitOfLengths from "classes/UnitOfLengths";

/**
 * @param {Number} number number to round to 1 digit. For example, 10.15 will become 10.2.
 */
const roundTo1Digit = (number) => {
  // Solution for rounding taken from https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary?page=1&tab=votes#tab-top
  return Math.round((number + Number.EPSILON) * 10) / 10;
};

/**
 * Round all the dimensions of the wall, doors, windows, etc. to 1 digit
 * @param {Wall} wall
 * @return {Wall} new wall object with all dimensions rounded
 */
export const roundWallDimensions = (wall) => {
  return new Wall(
    roundTo1Digit(wall.width),
    roundTo1Digit(wall.height),
    wall.doors.map((door) => {
      return roundDoorDimensions(door);
    }),
    wall.windows.map((window) => {
      return roundWindowDimensions(window);
    }),
    wall.unitOfLength
  );
};

/**
 *
 * @param {Door} door
 * @param {UnitOfLengths} from
 * @param {UnitOfLengths} to
 */
export const convertDoorDimensions = (door, from, to) => {
  return roundDoorDimensions(
    convertDoorDimensionsWithFactor(door, getConversionFactor(from, to))
  );
};

const roundDoorDimensions = (door) => {
  return new Door(
    roundTo1Digit(door.width),
    roundTo1Digit(door.height),
    roundTo1Digit(door.distanceFromRight)
  );
};

/**
 *
 * @param {Window} window
 * @param {UnitOfLengths} from
 * @param {UnitOfLengths} to
 */
export const convertWindowDimensions = (window, from, to) => {
  return roundWindowDimensions(
    convertWindowDimensionsWithFactor(window, getConversionFactor(from, to))
  );
};

const roundWindowDimensions = (window) => {
  return new Window(
    roundTo1Digit(window.width),
    roundTo1Digit(window.height),
    roundTo1Digit(window.distanceFromRight),
    roundTo1Digit(window.distanceFromFloor)
  );
};

/**
 * Convert all dimensions of a door by using the conversion factor
 * @param {Door} door
 * @param {number} conversionFactor multiply the dimensions of the door by this value
 * @return {Door} new door object with converted values
 */
const convertDoorDimensionsWithFactor = (door, conversionFactor) => {
  return new Door(
    door.width * conversionFactor,
    door.height * conversionFactor,
    door.distanceFromRight * conversionFactor
  );
};

/**
 * Convert all dimensions of a window by using the conversion factor
 * @param {Window} window
 * @param {number} conversionFactor multiply the dimensions of the window by this value
 * @return {Window} new window object with converted values
 */
const convertWindowDimensionsWithFactor = (window, conversionFactor) => {
  return new Window(
    window.width * conversionFactor,
    window.height * conversionFactor,
    window.distanceFromRight * conversionFactor,
    window.distanceFromFloor * conversionFactor
  );
};

/**
 * Get the factor to convert from one unit of length to the other.
 * For example, if you're converting from UnitOfLengths.meters to UnitOfLengths.centimeters, the return value will be 100.
 * @param {UnitOfLengths} fromUnitOfLength
 * @param {UnitOfLengths} toUnitOfLength
 */
const getConversionFactor = (fromUnitOfLength, toUnitOfLength) => {
  switch (fromUnitOfLength) {
    case UnitOfLengths.meters:
      switch (toUnitOfLength) {
        case UnitOfLengths.meters:
          return 1;
        case UnitOfLengths.centimeters:
          return 100;
        case UnitOfLengths.millimeters:
          return 1000;
        case UnitOfLengths.feet:
          return 3.28084;
        case UnitOfLengths.inches:
          return 39.3701;
        default:
          break;
      }
      break;
    case UnitOfLengths.centimeters:
      switch (toUnitOfLength) {
        case UnitOfLengths.meters:
          return 0.01;
        case UnitOfLengths.centimeters:
          return 1;
        case UnitOfLengths.millimeters:
          return 10;
        case UnitOfLengths.feet:
          return 0.0328084;
        case UnitOfLengths.inches:
          return 0.393701;
        default:
          break;
      }
      break;
    case UnitOfLengths.feet:
      switch (toUnitOfLength) {
        case UnitOfLengths.meters:
          return 0.3048;
        case UnitOfLengths.centimeters:
          return 30.48;
        case UnitOfLengths.millimeters:
          return 304.8;
        case UnitOfLengths.feet:
          return 1;
        case UnitOfLengths.inches:
          return 12;
        default:
          break;
      }
      break;
    case UnitOfLengths.inches:
      switch (toUnitOfLength) {
        case UnitOfLengths.meters:
          return 0.0254;
        case UnitOfLengths.centimeters:
          return 2.54;
        case UnitOfLengths.millimeters:
          return 25.4;
        case UnitOfLengths.feet:
          return 0.0833333;
        case UnitOfLengths.inches:
          return 1;
        default:
          break;
      }
      break;
    case UnitOfLengths.millimeters:
      switch (toUnitOfLength) {
        case UnitOfLengths.meters:
          return 0.001;
        case UnitOfLengths.centimeters:
          return 0.1;
        case UnitOfLengths.millimeters:
          return 1;
        case UnitOfLengths.feet:
          return 0.00328084;
        case UnitOfLengths.inches:
          return 0.0393701;
        default:
          break;
      }
      break;
    default:
      break;
  }

  return 1;
};

/**
 * Convert and round the dimension to 1 digit
 * @param {number} dimension
 * @param {UnitOfLengths} unitOfLength convert to this unit of length
 */
export const convertDimension = (dimension, unitOfLength) => {
  return roundTo1Digit(convert(dimension, UnitOfLengths.meters, unitOfLength));
};

/**
 *
 * @param {number} number
 * @param {UnitOfLengths} from
 * @param {UnitOfLengths} to
 */
export const convert = (number, from, to) => {
  return number * getConversionFactor(from, to);
};

/**
 * Create a new all with the same dimensions as the wall passed in, but with a new unit of length
 * @param {Wall} wall the wall to convert to a new unit of length
 * @param {UnitOfLengths} newUnitOfLength
 * @returns {Wall} new wall object with the converted dimensions. If the wall is already in the same unit of length, the wall object passed in parameter will be returned.
 */
export const changeWallUnitOfLength = (wall, newUnitOfLength) => {
  if (wall.unitOfLength === newUnitOfLength) {
    return wall;
  }

  const conversionFactor = getConversionFactor(
    wall.unitOfLength,
    newUnitOfLength
  );

  return new Wall(
    wall.width * conversionFactor,
    wall.height * conversionFactor,
    wall.doors?.map((door) =>
      convertDoorDimensionsWithFactor(door, conversionFactor)
    ),
    wall.windows?.map((window) =>
      convertWindowDimensionsWithFactor(window, conversionFactor)
    ),
    newUnitOfLength
  );
};

/**
 * Get the distance from the left side of the door to the left side of the wall it is on
 * @param {Door} door the door
 * @param {Wall} onWall the wall the door is on
 */
export const getDoorDistanceFromLeft = (door, onWall) => {
  return onWall.width - (door.distanceFromRight + door.width);
};

/**
 * Get the distance from the left side of the window to the left side of the wall it is on
 * @param {Window} window the window
 * @param {Wall} onWall the wall the window is on
 */
export const getWindowDistanceFromLeft = (window, onWall) => {
  return onWall.width - (window.distanceFromRight + window.width);
};

export const getTotalSurfaceAmount = (wall) => {
  // Calculate total surface based on all the wall values
  let surface = wall.width * wall.height;

  // Substract the surface of each door
  wall.doors.forEach((door) => {
    surface -= door.width * door.height;
  });

  // Substract the surface of each window
  wall.windows.forEach((window) => {
    surface -= window.width * window.height;
  });

  surface = Math.round(surface * 100) / 100;

  return surface;
};

/**
 * 2D Rectangle define by the coordinates of his 4 sides: top, right, bottom, left
 */
class Rectangle {
  /**
   * @param {Number} top
   * @param {Number} right
   * @param {Number} bottom
   * @param {Number} left
   */
  constructor(top, right, bottom, left) {
    this.top = top;
    this.right = right;
    this.bottom = bottom;
    this.left = left;
  }
}

/**
 * Convert the wall to a rectangle where (0,0) is the bottom-right edge of the wall.
 * @param {Wall} wall
 * @returns {Rectangle} rectangle with all the edges of the wall
 */
const convertWallToRectangle = (wall) => {
  return new Rectangle(wall.height, 0, 0, -wall.width);
};

/**
 * Convert the door to a rectangle where (0,0) is the right edge of the wall the door is on. The door will always be in negative x.
 * @param {Door} door
 * @returns {Rectangle} rectangle with all the edges of the door
 */
const convertDoorToRectangle = (door) => {
  return new Rectangle(
    door.height,
    -door.distanceFromRight,
    0,
    -door.distanceFromRight - door.width
  );
};

/**
 * Convert the window to a rectangle where (0,0) is the right edge of the wall the window is on. The window will always be in negative x.
 * @param {Window} window
 * @returns {Rectangle} rectangle with all the edges of the window
 */
const convertWindowToRectangle = (window) => {
  return new Rectangle(
    window.distanceFromFloor + window.height,
    -window.distanceFromRight,
    window.distanceFromFloor,
    -window.distanceFromRight - window.width
  );
};

/**
 * Check if two rectangles are overlapping
 * @param {Rectangle} rectangle1
 * @param {Rectangle} rectangle2
 * @returns true if one of the rectangle has a point in the other rectangle
 */
const areRectanglesOverlapping = (rectangle1, rectangle2) => {
  return !(
    rectangle1.left > rectangle2.right ||
    rectangle1.right < rectangle2.left ||
    rectangle1.top < rectangle2.bottom ||
    rectangle1.bottom > rectangle2.top
  );
};

/**
 * Is rectangle inside otherRectangle. The edges can be overlapping.
 * @param {Rectangle} rectangle
 * @param {Rectangle} otherRectangle
 */
const isRectangleInsideOther = (rectangle, otherRectangle) => {
  return (
    otherRectangle.left <= rectangle.left &&
    otherRectangle.top >= rectangle.top &&
    otherRectangle.bottom <= rectangle.bottom &&
    otherRectangle.right >= rectangle.right
  );
};

/**
 *
 * @param {Wall} wall
 * @returns {{partOf:String, atIndex:Number, rectangle:Rectangle}[]} Array of all the door and windows on the wall with the rectangle reprensenting them.
 */
const allDoorsAndWindows = (wall) => {
  const allDoorsAndWindows = [];
  allDoorsAndWindows.push(
    ...wall.doors.map((door, index) => {
      return {
        partOf: "doors",
        atIndex: index,
        rectangle: convertDoorToRectangle(door),
      };
    })
  );
  allDoorsAndWindows.push(
    ...wall.windows.map((window, index) => {
      return {
        partOf: "windows",
        atIndex: index,
        rectangle: convertWindowToRectangle(window),
      };
    })
  );
  return allDoorsAndWindows;
};

/**
 * Check if any doors or windows of the wall overlap each other
 * @param {Wall} wall
 * @returns {Boolean} True if any doors or windows overlap, false otherwise. False if there is no doors or windows.
 */
export const hasOverlappingDoorsOrWindows = (wall) => {
  return Boolean(GetFirstOverlappingDoorOrWindow(wall));
};

/**
 * Find the first door or window that overlaps with another door or window
 * @param {Wall} wall wall containing the doors and windows to check
 * @returns {{overlapping:{partOf:String, atIndex:Number}, overlapPartner:{partOf:String, atIndex:Number}}}
 *                   returns an object describing both the overlapping door or window with what it is overlapping. These objects contain two values, `partOf` and `atIndex`.
 *                   In the case of a door, the `partOf` will be equal to "doors" and in the case of a window, it will be "windows"
 *                   The `atIndex` value refer to the index of the door or window inside it's own array in the wall.
 *                   returns null if there is nothing overlapping
 *                   For example, if the 1st door is overlapping with the 2nd door, the return will be:
 *                   { overlapping: {partOf: "doors", atIndex: 0}, overlapPartner: {partOf: "doors", atIndex: 1} }
 */
export const GetFirstOverlappingDoorOrWindow = (wall) => {
  const doorsAndWindows = allDoorsAndWindows(wall);

  for (let i = 0; i < doorsAndWindows.length; i++) {
    const doorOrWindow = doorsAndWindows[i];
    for (let j = i + 1; j < doorsAndWindows.length; j++) {
      const otherDoorOrWindow = doorsAndWindows[j];
      if (
        areRectanglesOverlapping(
          doorOrWindow.rectangle,
          otherDoorOrWindow.rectangle
        )
      ) {
        return {
          overlapping: {
            partOf: doorOrWindow.partOf,
            atIndex: doorOrWindow.atIndex,
          },
          overlapPartner: {
            partOf: otherDoorOrWindow.partOf,
            atIndex: otherDoorOrWindow.atIndex,
          },
        };
      }
    }
  }

  return {};
};

/**
 * Get every windows and doors that are outside of the wall boundaries
 * @param {Wall} wall
 * @returns {{partOfArray:String, atIndex:Number}[]}
 *          returns an array of all objects that are on the wall, but that exceed it's boundaries
 *          each object is defined by the array it is part of (i.e. "doors" or "windows")
 *          and the index at which it is in this array.
 */
export const getEverythingOutsideWall = (wall) => {
  const doorsAndWindows = allDoorsAndWindows(wall);
  const wallRectangle = convertWallToRectangle(wall);

  const everythingOutsideOfWall = [];
  doorsAndWindows.forEach((onWall) => {
    if (!isRectangleInsideOther(onWall.rectangle, wallRectangle)) {
      everythingOutsideOfWall.push({
        partOfArray: onWall.partOf,
        atIndex: onWall.atIndex,
      });
    }
  });

  return everythingOutsideOfWall;
};

/**
 * Round up the value to the next multiple of
 * @param {Number} value
 * @param {Number} multiple
 */
export const roundUp = (value, multiple) => {
  return Math.ceil(value / multiple) * multiple;
};

/**
 * Get the total width of an element, some elements consists of several parts with gaps
 * @param {Object} element
 */
export const getTotalElementWidth = (element) => {
  const dimensions = element.dimensions;
  let width;

  if (dimensions.horizontalParts) {
    width = dimensions.width * dimensions.horizontalParts;
    width += (dimensions.horizontalParts - 1) * dimensions.horizontalGap;
  } else {
    width = dimensions.width;
  }

  return width;
};
