import React from "react";

import { PDFViewer } from "@react-pdf/renderer";
import PdfUserChoices from "components/PdfUserChoices";

import styledPdf from "@react-pdf/styled-components";
import styled from "styled-components";

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPDFViewer = styledPdf(PDFViewer)`
  width: 80%;
  height: 90vh;
`;

/**
 * Show a PDF in a viewport
 * All props are directly passed to the pdf component
 * This is only used for testing at the moment
 */
const PdfViewerPage = (props) => {
  return (
    <Layout>
      <StyledPDFViewer>
        <PdfUserChoices {...props} />
      </StyledPDFViewer>
    </Layout>
  );
};

export default PdfViewerPage;
