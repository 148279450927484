/**
 * Informations for an image to be drawn on a canvas
 */
class ImageToDrawOnCanvas {
  /**
   *
   * @param {HTMLImageElement} image image that should be drawn
   * @param {{width: number, height: number}} dimensionsPx the dimensions of the image to draw in pixels
   * @param {{x: number, y: number}} topLeft where in the canvas the top left point of the image should be located
   * @param {string=} [globalCompositeOperation] how this image will be blended with the existing images drawn on the canvas.
   *                                          You can see all possible values here: https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
   * @param {number} [globalAlpha] the alpha (aka opacity) to use when drawing the image
   */
  constructor(
    image,
    dimensionsPx,
    topLeft,
    globalCompositeOperation = "source-over",
    globalAlpha = 1.0
  ) {
    this.image = image;
    this.dimensionsPx = dimensionsPx;
    this.topLeft = topLeft;
    this.globalCompositeOperation = globalCompositeOperation;
    this.globalAlpha = globalAlpha;
  }
}

export default ImageToDrawOnCanvas;
