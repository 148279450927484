import axios from "axios";

import { isProduction } from "helpers/env";

const crmUrlDev = "https://artedev.azure-api.net/dyn/configurator/simulation";
const crmHeaderKeyDev = "e51d372a422b42bf962e9492daa61560";

const crmUrl = "https://arte.azure-api.net/dyn/configurator/simulation";
const crmHeaderKey = "b85643acd91b47f1985ff675428de34b";

/**
 * Send the user information to the crm system of Arte
 * @param {Object} user Main user object containing all the user information
 * @param {string} user.name The name of the user
 * @param {string} user.lastName The last name of the user
 * @param {string} user.email The email of the user
 */
export const sendUserInfoToCRM = (user, panoramiqueName, collectionCode) => {
  if (!panoramiqueName) {
    return Promise.reject(new Error("There was no panoramiqueName passed"));
  }

  if (!user) {
    return Promise.reject(new Error("There was no user object passed"));
  }

  if (!user.name) {
    return Promise.reject(new Error("The user object does not contain a name"));
  }

  if (!user.lastName) {
    return Promise.reject(
      new Error("The user object does not contain a last name")
    );
  }

  if (!user.email) {
    return Promise.reject(
      new Error("The user object does not contain an email")
    );
  }

  const currentDate = new Date(Date.now());
  const urlToUse = isProduction() ? crmUrl : crmUrlDev;
  const headerKeyToUse = isProduction() ? crmHeaderKey : crmHeaderKeyDev;

  return axios.post(
    urlToUse,
    {
      Identity: {
        Origin: "Configurator",
        FirstName: user.name,
        LastName: user.lastName,
        Email: user.email,
      },
      Date: currentDate.toISOString(),
      Collection: collectionCode,
      Dessin: panoramiqueName,
    },
    {
      headers: {
        "Ocp-Apim-Subscription-Key": headerKeyToUse,
      },
    }
  );
};
