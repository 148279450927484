import React from "react";
import PropTypes from "prop-types";

// Components
import WallDimensionsFormFields from "./WallDimensionsFormFields";
import UnitOfLengthSelector from "components/UnitOfLengthSelector";

// Helpers
import localizer from "localization/localizer";
import { Formik, Form } from "formik";
import {
  roundWallDimensions,
  changeWallUnitOfLength,
  getEverythingOutsideWall,
  GetFirstOverlappingDoorOrWindow,
} from "helpers/WallHelpers";
import { wallHeightLimits, wallWidthLimits } from "./DimensionsConstraints";

// Classes
import Wall from "classes/Wall";
import UnitOfLengths from "classes/UnitOfLengths";

// Styling
import styled from "styled-components";
import { Button } from "components/commonStyledComponents";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FormFieldsLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
  font-size: 1.2rem;
  margin-top: 24px;
`;

const Separator = styled.hr`
  width: 100%;
  color: rgba(0, 0, 0, 0.1);

  margin: 24px 0;
`;

const UnitOfLengthLayout = styled.div`
  display: flex;
  flex-flow: column;
`;

// Constant values

const localizerInPage = localizer.wallDimensionsPage.customizeDimensions;

const defaultWall = new Wall("", "", [], [], UnitOfLengths.meters);

//  Helper functions

/**
 * Gives the localized type of an object on a wall solely based on the array containing it
 * @param {String} containingArrayName the name of the array containing the object. i.e. "doors" or "windows"
 * @returns {String} localized string with the type of the object. i.e. "door" or "window"
 */
const localizedObjectType = (containingArrayName) => {
  switch (containingArrayName) {
    case "doors":
      return localizer.door;
    case "windows":
      return localizer.window;
    default:
      return "unknown";
  }
};

/**
 * @param {{partOf:String, atIndex: Number}} overlapping
 * @param {{partOf:String, atIndex: Number}} overlapPartner
 * @returns {String} an error message describing an overlap between two objects on a wall
 */
const localizedOverlapMessage = (overlapping, overlapPartner) => {
  return localizer.formatString(
    localizerInPage.errors.overlapsWith,
    localizedObjectType(overlapping.partOf),
    localizedObjectType(overlapPartner.partOf),
    overlapPartner.atIndex + 1 // + 1 because an index starts at 0, but it makes more sense for a user to start at 1
  );
};

/**
 * Add errors related to overlapping objects on a wall
 * @param {Object} currentErrors
 * @param {Wall} wall
 * @returns {Object} a new object containing the overlapping errors and the previous errors. Returns currentErrors if there are no overlapping errors.
 */
const addOverlappingErrors = (currentErrors, wall) => {
  const overlappingErrors = {};

  const { overlapping, overlapPartner } = GetFirstOverlappingDoorOrWindow(wall);

  if (overlapping) {
    overlappingErrors[
      `${overlapping.partOf}.${overlapping.atIndex}`
    ] = localizedOverlapMessage(overlapping, overlapPartner);
    overlappingErrors[
      `${overlapPartner.partOf}.${overlapPartner.atIndex}`
    ] = localizedOverlapMessage(overlapPartner, overlapping);

    return { ...currentErrors, ...overlappingErrors };
  }

  return currentErrors;
};

/**
 * Add errors related to objects that are out of the wall boundaries
 * @param {Object} currentErrors
 * @param {Wall} wall
 * @returns {Object} a new object containing both previous errors and the errors added by this function.
 *                   If there is no object out of the wall, the `currentErrors` object is returned.
 */
const addOutOfWallErrors = (currentErrors, wall) => {
  const outOfWallErrors = {};

  const everythingOutOfWall = getEverythingOutsideWall(wall);
  if (everythingOutOfWall.length > 0) {
    everythingOutOfWall.forEach((outOfWall) => {
      outOfWallErrors[
        `${outOfWall.partOfArray}.${outOfWall.atIndex}`
      ] = localizer.formatString(
        localizerInPage.errors.outsideWallBoundaries,
        localizedObjectType(outOfWall.partOfArray)
      );
    });
    return { ...currentErrors, ...outOfWallErrors };
  }

  return currentErrors;
};

/**
 * Form where the user can define the dimensions of the wall as well as doors and windows on that wall.
 */
const CustomizeWallDimensions = ({
  initialWallDimensions,
  onWallConfirmedByUser,
  onWallDimensionsChanged,
}) => {
  return (
    <Formik
      initialValues={
        initialWallDimensions ? initialWallDimensions : defaultWall
      }
      validate={(values) => {
        const wall = values;

        if (onWallDimensionsChanged) {
          // It is not the cleanest to call this callback in "validate", however, there are no dedicated "onChanged" call in Formik.
          // This thread describes this issue: https://github.com/formium/formik/issues/1633
          // If we want to try an alternative solution, we can try this solution: https://github.com/formium/formik/issues/1633#issuecomment-520121543
          onWallDimensionsChanged(wall);
        }

        let errors = {};
        // The order in which the errors are added mather, only one error will stay on each field at the end
        // so the last error added will show up, once it is fixed, the other one will show up, etc.
        errors = addOverlappingErrors(errors, wall);
        errors = addOutOfWallErrors(errors, wall);
        return errors;
      }}
      onSubmit={(values) => {
        if (onWallConfirmedByUser) {
          onWallConfirmedByUser(values);
        }
      }}
    >
      {({ isValid, values, setValues }) => {
        const changeUnitOfLenght = (unitOfLength) => {
          const wallWithProperUnitOfLength = changeWallUnitOfLength(
            values,
            unitOfLength
          );
          // Rounding is necessary for two reasons:
          // 1) It looks better for the user if there isn't a lot of decimals
          // 2) It means the resulting numbers are compatible with the step set on the input[type=number] elements of the form
          // Since we set the values to be the whole wall object, the values of the form will contain the unit of length used in the wall
          setValues(roundWallDimensions(wallWithProperUnitOfLength));
        };

        return (
          <StyledForm>
            <UnitOfLengthLayout>
              <Separator />
              <UnitOfLengthSelector
                CurrentUnitOfLength={values.unitOfLength}
                onUnitOfLengthChanged={changeUnitOfLenght}
              />
              <Separator />
            </UnitOfLengthLayout>
            <FormFieldsLayout>
              <WallDimensionsFormFields
                widthField={{
                  name: "width",
                  min: wallWidthLimits.min,
                }}
                heightField={{
                  name: "height",
                  min: wallHeightLimits.min,
                }}
              />
              <SubmitButton type="submit" disabled={!isValid}>
                {localizer.next}
              </SubmitButton>
            </FormFieldsLayout>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

CustomizeWallDimensions.propTypes = {
  /** Initial wall dimensions that will fill the form before the user does any changes */
  initialWallDimensions: PropTypes.instanceOf(Wall),
  /**
   * Function called when the user has finished specifying the dimensions of the wall and everything on it
   * @param {Wall} wall wall specified by the user.
   */
  onWallConfirmedByUser: PropTypes.func.isRequired,
  /**
   * Function called when any of the fields in the form are changed or when or when doors or windows are added/removed
   * @param {Wall} wall the wall that is currently defined by the user
   */
  onWallDimensionsChanged: PropTypes.func,
  /** The wall must at least be of these dimensions (in meters) */
  minimumWallDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  /**
   * Sentence explaining why the wall must be at least a certain dimension.
   * This sentence will be used as a special note to bring the user's attention on why the wall dimensions are limited.
   * The minimum dimensions of the wall will be appended to this sentence.
   * For example, a sentence like "To contain the decoration you picked, the wall must be at least" combined with minimum dimensions of 2 x 2 m will result in:
   * "To contain the decoration you picked, the wall must be at least 2m x 2m."
   */
  reasonForMinWallDimensions: PropTypes.string,
};

export default CustomizeWallDimensions;
