import { ImageOfMeasuredContent } from "classes/Image";
import { getMatchingImage } from "helpers/ImageHelper";

import window1By1 from "resources/images/windows/window-1x1.png";
import window1By2 from "resources/images/windows/window-1x2.png";
import window1By3 from "resources/images/windows/window-1x3.png";
import window1By4 from "resources/images/windows/window-1x4.png";
import window2By1 from "resources/images/windows/window-2x1.png";

// The dimensions of the window are not necessarily taken from the real dimensions of the window in the picture
// These dimensions are not that important because we stretch the windows to whatever the user decides for the dimensions
// The important part is the ratio between the width and the height, that needs to be correct
const allWindowsImages = [
  new ImageOfMeasuredContent(window1By1, { width: 100, height: 100 }),
  new ImageOfMeasuredContent(window1By2, { width: 100, height: 200 }),
  new ImageOfMeasuredContent(window1By3, { width: 100, height: 300 }),
  new ImageOfMeasuredContent(window1By4, { width: 100, height: 400 }),
  new ImageOfMeasuredContent(window2By1, { width: 200, height: 100 }),
];

/**
 * Get the window image whose sides are the closest to match the provided ratio
 * @param {number} heightOnWidthRatio the ratio obtained by dividing the height of door by it's width
 * @returns {string} the src of the window image that is the best match
 */
export const getMatchingWindowImage = (heightOnWidthRatio) => {
  return getMatchingImage(heightOnWidthRatio, allWindowsImages).imageSrc;
};
