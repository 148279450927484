import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  // Remove the outline shown by default on focus, there is a custom style for focus
  outline: none;

  cursor: pointer;

  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  color: grey;

  // Background is a circle
  border-radius: 50%;

  // Center the icon
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    background-color: whitesmoke;
    border: black 2px solid;
    // Don't need the outline as the border fills this role
    outline: none;
  }
  &:hover {
    background-color: whitesmoke;
    color: black;
  }
  &:disabled {
    color: gainsboro;
    background: none;
    cursor: default;
  }
`;

/**
 * Button that only contains a FontAwesomeIcon.
 * You have to provide the FontAwesomeIcon in the 'icon' prop.
 * The button will not stay focused after the user clicks it
 * Any props supported by a button can be passed to it.
 */
function ButtonFontAwesomeIcon(props) {
  const { className, icon, ...remainingProps } = props;
  return (
    <StyledButton
      className={className}
      {...remainingProps}
      type="button"
      onMouseDown={(mouseEvent) => {
        // To avoid focus the button retaining focus after clicking on it
        mouseEvent.preventDefault();
      }}
    >
      {icon}
    </StyledButton>
  );
}

ButtonFontAwesomeIcon.propTypes = {
  /** FontAwesomeIcon to display inside the button. This should be a react component. */
  icon: PropTypes.object.isRequired,
};

export default ButtonFontAwesomeIcon;
