import React from "react";
import PropTypes from "prop-types";

// Components
import PdfTileColorVariation from "components/pdf/PdfTileColorVariation";

// Styling
import styled from "@react-pdf/styled-components";

const Layout = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

// Assumes the grid is laid out on two columns, hence the height 50%
const ColorVariation = styled(PdfTileColorVariation)`
  width: ${(props) => `${100 / props.amountPerRow}%`};
  height: 100%;
  padding: 4px;
`;

/**
 * Grid containing color variations of tiles that are laid out on 2 rows.
 */
const PdfTileColorVariationsGrid = ({
  colorVariations,
  amountPerRow,
  style,
}) => {
  const colorVariationsInGrid = colorVariations.map((colorVariation) => {
    return (
      <ColorVariation
        key={colorVariation.code}
        amountPerRow={colorVariations.length}
        tileImageSrc={colorVariation.url}
        tileReference={colorVariation.code}
      />
    );
  });
  return (
    <Layout style={style} amountPerRow={amountPerRow}>
      {colorVariationsInGrid}
    </Layout>
  );
};

PdfTileColorVariationsGrid.propTypes = {
  /** Array of color vartions to show */
  colorVariations: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
  ),
  /** Amount of tiles color variation that should be shown on each row. 2 by default */
  amountPerRow: PropTypes.number,
};

PdfTileColorVariationsGrid.defaultProps = {
  amountPerRow: 2,
};

export default PdfTileColorVariationsGrid;
