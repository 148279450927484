import React from "react";
import PropTypes from "prop-types";

// Styling
import styled from "@react-pdf/styled-components";
import { NormalText } from "./pdfCommonStyles";

const Layout = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TileImage = styled.Image`
  flex: 1 0;
  flex-basis: 75%;
  object-fit: contain;
`;

const TileReference = styled(NormalText)`
  flex: 0 0 auto;
  padding: 6px 0;
`;

/**
 * Tile color variation with it's reference number
 * Meant to be used in a pdf created with @react-pdf
 */
const PdfTileColorVariation = ({ style, tileImageSrc, tileReference }) => {
  return (
    <Layout style={style}>
      <TileImage src={tileImageSrc} />
      <TileReference>{`# ${tileReference}`}</TileReference>
    </Layout>
  );
};

PdfTileColorVariation.propTypes = {
  /** Path to the image to use as "src" for the tile */
  tileImageSrc: PropTypes.string.isRequired,
  /** Reference number of the tile */
  tileReference: PropTypes.number.isRequired,
};

export default PdfTileColorVariation;
