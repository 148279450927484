// Data
import { getMatchingDoorImage } from "data/DoorImagesRepository";
import { getMatchingWindowImage } from "data/WindowsImagesRepository";
import { roomWithBigWindow } from "data/RoomImagesRepository";
import maskToHideWallUnderFloor from "resources/images/rooms/room-floor-mask.png";

// Helpers
import {
  getDoorDistanceFromLeft,
  getWindowDistanceFromLeft,
} from "helpers/WallHelpers";
import {
  howToDrawInCanvasHeight,
  willCreateImagesForCanvas,
  willCreateImageForCanvas,
} from "helpers/CanvasHelper";

/**
 * Create objects that define how to draw the door images on a canvas.
 * @param {height} canvasHeightPx dimensions of the canvas the door should be drawn on. The doors will be draw at the bottom of the canvas.
 * @param {Wall} wall wall containing the doors informations
 * @param {number} pixelPerCm conversion from pixels to cm
 * @returns {Promise<ImageToDrawOnCanvas[]>} Promise that resolves to a list of door images that can be drawn on a canvas
 */
export const willCreateDoorImagesForCanvas = (
  canvasHeightPx,
  wall,
  pixelPerCm
) => {
  const doorImagesDescriptions = [];
  if (wall.doors.length > 0) {
    wall.doors.forEach((door) => {
      const doorDimensionsPx = {
        width: door.width * pixelPerCm,
        height: door.height * pixelPerCm,
      };

      const doorTopLeft = {
        x: getDoorDistanceFromLeft(door, wall) * pixelPerCm,
        y: canvasHeightPx - doorDimensionsPx.height,
      };

      doorImagesDescriptions.push({
        src: getMatchingDoorImage(door.height / door.width),
        dimensionsPx: doorDimensionsPx,
        topLeft: doorTopLeft,
      });
    });
  }

  /** Return a promise that will resolve when all the door images are correctly loaded */
  return willCreateImagesForCanvas(doorImagesDescriptions);
};

/**
 * Create objects that define how to draw the window images on a canvas.
 * @param {height} canvasHeightPx dimensions of the canvas the windows should be drawn on.
 * @param {Wall} wall wall containing the windows informations
 * @param {number} pixelPerCm conversion from pixels to cm
 * @returns {Promise<ImageToDrawOnCanvas[]>} Promise that resolves to a list of window images that can be drawn on a canvas
 */
export const willCreateWindowImagesForCanvas = (
  canvasHeightPx,
  wall,
  pixelPerCm
) => {
  const windowsImagesDescriptions = [];
  wall.windows.forEach((window) => {
    const windowDimensionsPx = {
      width: window.width * pixelPerCm,
      height: window.height * pixelPerCm,
    };

    const windowDistanceFromLeft =
      getWindowDistanceFromLeft(window, wall) * pixelPerCm;
    const windowDistanceFromFloorPx = window.distanceFromFloor * pixelPerCm;

    const windowTopLeft = {
      x: windowDistanceFromLeft,
      y: canvasHeightPx - windowDimensionsPx.height - windowDistanceFromFloorPx,
    };

    windowsImagesDescriptions.push({
      src: getMatchingWindowImage(window.height / window.width),
      dimensionsPx: windowDimensionsPx,
      topLeft: windowTopLeft,
    });
  });
  return willCreateImagesForCanvas(windowsImagesDescriptions);
};

/**
 * @typedef {{start: number, end: number}} Range
 */

/**
 * Inside a given range (called "wholeRange"), find all the ranges that are not forbidden (so are available)
 * @param {number} wholeRange
 * @param {Range} forbiddenRanges
 * @returns {Range[]} the ranges in the whole range that are not forbidden
 */
const findAvailableRanges = (wholeRange, forbiddenRanges) => {
  // We can sort the forbidden ranges using only their start because we assume there is no overlap between the forbidden ranges
  // So the result of the sorting will be an array of ranges that are in order from left to right (so from the beginning of the whole range to the end)
  const sortedforbiddenRangesLeftToRight = [...forbiddenRanges];
  sortedforbiddenRangesLeftToRight.sort((rangeA, rangeB) => {
    // If A is greater than B, the result is positive, which means A and B are swapped
    // If B is greated than A, the result is negative, which keeps A and B in the same order
    return rangeA.start - rangeB.start;
  });

  // Determine all the available ranges by removing the forbidden ranges from the whole range
  const availableRanges = [];
  let currentPositionInFullRange = 0;
  sortedforbiddenRangesLeftToRight.forEach((forbiddenRange) => {
    if (currentPositionInFullRange >= wholeRange) {
      // If a forbidden range went over the end of the whole range, just return until we exit the forEach
      return;
    }
    // The position we are currently at is actually directly on the start of a forbidden range, jump to the end of that forbidden range
    if (currentPositionInFullRange === forbiddenRange.start) {
      currentPositionInFullRange = forbiddenRange.end;
    } else {
      // All the space between the current position and the next forbidden range is an available range!
      availableRanges.push({
        start: currentPositionInFullRange,
        end: forbiddenRange.start,
      });
      // Jump to the end of that forbidden range to check for the next available range
      currentPositionInFullRange = forbiddenRange.end;
    }
  });

  // If none of the forbidden range reached the end of the full range, include the range between the last forbidden range and the end of the full range.
  if (currentPositionInFullRange < wholeRange) {
    availableRanges.push({
      start: currentPositionInFullRange,
      end: wholeRange,
    });
  }

  return availableRanges;
};

/**
 * Find the object placement that respects these two rulse:
 *  Is as close as possible to "mustBeClosestToPoint"
 *  Is inside "range"
 * @param {number} objectSize
 * @param {Range} range
 * @param {number} mustBeClosestToPoint
 * @returns {{position: number, distanceFromPoint: number}| undefined} return the placement defined by the object's position and it's distance from the desired point
 */
const getClosestObjectPlacementInRange = (
  objectSize,
  range,
  mustBeClosestToPoint
) => {
  if (range.end - range.start < objectSize) {
    // Object doesn't event fit in the range, don't even try
    return undefined;
  }

  const isPointWithinRange =
    range.start <= mustBeClosestToPoint && mustBeClosestToPoint <= range.end;
  if (isPointWithinRange) {
    const tentativeObjectStart = mustBeClosestToPoint - objectSize / 2;
    const tentativeObjectEnd = mustBeClosestToPoint + objectSize / 2;

    const isObjectBeforeRangeStart = tentativeObjectStart < range.start;
    const isObjectAfterRangeEnd = range.end < tentativeObjectEnd;
    const canObjectBeAtPointAndStayInRange =
      !isObjectBeforeRangeStart && !isObjectAfterRangeEnd;

    if (canObjectBeAtPointAndStayInRange) {
      // Great, just place the object right on the point
      return { position: mustBeClosestToPoint, distanceFromPoint: 0 };
    } else {
      // The object can't be placed directly on the point because it would make it go out of the range, translate it to make it fit in the range
      let placement = undefined;
      if (isObjectBeforeRangeStart) {
        // Object goes over the start, move it so it is aligned exactly on the range start
        placement = range.start + objectSize / 2;
      } else {
        // Object goes over the end, move it so it is aligned exactly on the range end
        placement = range.end - objectSize / 2;
      }
      return {
        position: placement,
        distanceFromPoint: Math.abs(mustBeClosestToPoint - placement),
      };
    }
  }

  const isPointBeforeStart = mustBeClosestToPoint < range.start;
  if (isPointBeforeStart) {
    // Object should be as close as possible to a point before the start of the range.
    // Then the extreme left of the object should be right on the range start.
    const placement = range.start + objectSize / 2;
    return {
      position: placement,
      distanceFromPoint: placement - mustBeClosestToPoint,
    };
  }

  const isPointAfterEnd = range.end < mustBeClosestToPoint;
  if (isPointAfterEnd) {
    // Object should be as close as possible to a point after the end of the range.
    // Then the extreme right of the object should be right on the range end.
    const placement = range.end - objectSize / 2;
    return {
      position: placement,
      distanceFromPoint: mustBeClosestToPoint - placement,
    };
  }
};

/**
 * Position an object on an axis knowing that this object has a certain size.
 * This function finds the "best" position assuming that placement on certain ranges of this axis are forbidden
 * @param {number} objectSize
 * @param {number} wholeRange the whole range where the object could eventually be placed (all forbidden zones should be included in that range). Its start is at 0.
 * @param {Range[]} forbiddenRanges the ranges (that should be within the whole range) where the object should not be placed. Assumes there is no overlapping ranges.
 * @param {number} preferedPlacement a value that is within the whole range that defines where we would prefer to place the object
 * @returns {number|undefined} if a valid placement was found, return the number which is the position of the wholeRangeOfPossibility. Otherwise return undefined.
 */
const findBestPlacement = (
  objectSize,
  wholeRange,
  forbiddenRanges,
  preferedPlacement
) => {
  const availableRanges = findAvailableRanges(wholeRange, forbiddenRanges);

  // Of all the available ranges, choose the one that allows for the furniture to be the closest to the prefered placement
  let bestPlacement = undefined;
  let currentBestDistanceToPoint = undefined;
  availableRanges.forEach((range) => {
    const placement = getClosestObjectPlacementInRange(
      objectSize,
      range,
      preferedPlacement
    );

    if (!placement) {
      return;
    }

    if (
      currentBestDistanceToPoint === undefined ||
      placement.distanceFromPoint < currentBestDistanceToPoint
    ) {
      // The object can be placed in this range and it's closer to the prefered placement, keep this placement
      bestPlacement = placement.position;
      currentBestDistanceToPoint = placement.distanceFromPoint;
    }
  });

  return bestPlacement;
};

/**
 * @param {ImageOfFurniture} furnitureImagetoUse
 * @param {{width: number, height: number}} canvasDimensionsPx
 * @param {number} floorHeightPx measurement in pixels, from the bottom of the canvas, where the floor ends and the wall begins
 * @param {{start: number, end: number}[]} forbiddenPlacementsPx locations on the X axis where the furniture should not be placed in the canvas. The locations are defined by ranges (start and end) where 0 is the left position on the canvas.
 * @param {number} pixelPerCm amount of pixels per cm
 * @returns {Promise<ImageToDrawOnCanvas|undefined>} Promise that resolves to an image or undefined in the case the furniture couldn't fit in the provided canvas
 */
export const willCreateFurnitureImageForCanvas = (
  furnitureImagetoUse,
  canvasDimensionsPx,
  floorHeightPx,
  forbiddenPlacementsPx,
  pixelPerCm
) => {
  const furnitureImageDimensionsPx =
    furnitureImagetoUse.getDimensionsInPixels(pixelPerCm);

  const onlyFurnitureWidthPx =
    furnitureImagetoUse.getFurnitureWidthInPixels(pixelPerCm);

  if (onlyFurnitureWidthPx > canvasDimensionsPx.width) {
    // The furniture is too wide for the canvas, don't create any furniture
    return Promise.resolve(undefined);
  }

  const desiredFurnitureCenterX = findBestPlacement(
    onlyFurnitureWidthPx,
    canvasDimensionsPx.width,
    forbiddenPlacementsPx,
    // Try to position the furniture using the rule of third (explanation of the concept: https://digital-photography-school.com/rule-of-thirds/)
    canvasDimensionsPx.width * 0.333
  );

  if (desiredFurnitureCenterX === undefined) {
    // No valid placement could be found, don't create any furniture!
    return Promise.resolve(undefined);
  }

  const imageStartToFurniture =
    furnitureImagetoUse.furnitureHorizontalPosition.startRatio *
    furnitureImageDimensionsPx.width;

  // We know where we want to place the center of the furniture, but we have to compute where the left of the furniture image is going to end up if there center of the furniture is there
  const leftOfFurnitureImageInX =
    desiredFurnitureCenterX - onlyFurnitureWidthPx / 2 - imageStartToFurniture;

  // Need to align the floor on the furniture image with the floor on the canvas
  const bottomY =
    canvasDimensionsPx.height -
    floorHeightPx +
    furnitureImagetoUse.floorHeightRatio * furnitureImageDimensionsPx.height;

  const topY = bottomY - furnitureImageDimensionsPx.height;

  const isFurnitureOutOfCanvas = topY < 0;
  if (isFurnitureOutOfCanvas) {
    // Don't display any furniture if it doesn't fit in the height available
    return Promise.resolve(undefined);
  }

  const furnitureTopLeft = {
    x: leftOfFurnitureImageInX,
    y: bottomY - furnitureImageDimensionsPx.height,
  };

  return willCreateImageForCanvas({
    src: furnitureImagetoUse.imageSrc,
    dimensionsPx: furnitureImageDimensionsPx,
    topLeft: furnitureTopLeft,
  });
};

/**
 * Take each door and convert it to a range (defined by a start and a end) in pixels
 * The "origin" (a.k.a value 0) is the left side of the wall
 * @param {Wall} wallInCm the wall containing the doors (all dimensions in cm)
 * @param {number} paddingAroundDoorsCm padding (in cm) to add around the door to make the resulting range slightly bigger
 * @param {number} pixelPerCm number of pixels per displayed per cm
 * @returns {{start: number, end: number}}
 */
const convertDoorsToRangesInPixels = (
  wallInCm,
  paddingAroundDoorsCm,
  pixelPerCm
) => {
  return wallInCm.doors.map((door) => {
    const doorDistanceFromLeft = getDoorDistanceFromLeft(door, wallInCm);
    return {
      start: (doorDistanceFromLeft - paddingAroundDoorsCm) * pixelPerCm,
      end:
        (doorDistanceFromLeft + door.width + paddingAroundDoorsCm) * pixelPerCm,
    };
  });
};

/**
 * @typedef {Object} CanvasBlueprint object describing the size a canvas should have as well as the images to draw on that canvas
 * @property {{width: number, height: number}} canvasDimensionsPx
 * @property {ImageToDrawOnCanvas[]} imagesToDrawOnCanvas
 */

/**
 * Will create a canvas blueprint asynchronously, which is why a promise is returned
 * @param {number} availableHeightPx available height in pixels to draw the canvas
 * @param {Wall} wallToDraw wall that should be draw on the canvas with all it's elements (doors, windows, etc.)
 * @param {Object} wallImage
 * @param {string} wallImage.src src of the image show on the wall
 * @param {{width: number, heigth: number}} wallImage.dimensionsCm dimensions of the wall image (in cm)
 * @param {{x: number, y: number}} wallImage.topLeftOffsetPx The offset of the wall image from top left origin of the canvas
 * @param {boolean} wallImage.hasTransparentAreas is there transparent areas in the image? That will be impact if certain filters are applied or not
 * @param {ImageOfFurniture} furnitureImage furniture image that will be draw on the canvas
 * @returns {Promise<CanvasBlueprint>} a promise that resolves to the canvas blueprint
 */
export const willCreateCanvasBlueprint = (
  availableHeightPx,
  wallToDraw,
  wallImage,
  furnitureImage,
  useRepetition
) => {
  const roomImage = roomWithBigWindow;

  const fullCanvasDimensionsCm = {
    width: wallToDraw.width,
    height: wallToDraw.height + roomImage.getWallStartHeightInCm(),
  };

  const pixelsPerCm = availableHeightPx / fullCanvasDimensionsCm.height;

  const { dimensions: roomDimensionsPx } = howToDrawInCanvasHeight(
    roomImage.realWorldDimensionsCm,
    pixelsPerCm
  );

  const howToDrawRoomImage = {
    src: roomImage.imageSrc,
    dimensionsPx: roomDimensionsPx,
    topLeft: { x: 0, y: availableHeightPx - roomDimensionsPx.height },
  };
  const willCreateRoomImage = willCreateImageForCanvas(howToDrawRoomImage);

  // Now that the floor image pixel height has been calculated, we can know exactly the amount of pixel the floor takes
  const wallStartHeightPx =
    roomImage.wallStartHeightRatio * roomDimensionsPx.height;
  const canvasHeightWallOnlyPx = availableHeightPx - wallStartHeightPx;

  // The canvas height contains both wall image and floor image
  // The canvas width matches the size of the wall that we want to show
  const canvasWidthpx = pixelsPerCm * wallToDraw.width;

  const canvasDimensionsPx = {
    width: canvasWidthpx,
    height: availableHeightPx,
  };

  // Compute how to draw the background image considering it has to match above the floor of the foreground image
  const { dimensions: wallImageDimensionsPx } = howToDrawInCanvasHeight(
    wallImage.dimensionsCm,
    pixelsPerCm
  );

  const xOffsetPx = wallImage.topLeftOffsetPx.x * pixelsPerCm;
  const yOffsetPx = wallImage.topLeftOffsetPx.y * pixelsPerCm;

  const howToDrawWallImage = {
    src: wallImage.src,
    dimensionsPx: wallImageDimensionsPx,
    topLeft: { x: xOffsetPx, y: yOffsetPx },
  };
  const willCreateWallImage = willCreateImageForCanvas({
    ...howToDrawWallImage,
    globalCompositeOperation: "multiply",
  });

  const howToDrawWallImageLeft = {
    src: wallImage.src,
    dimensionsPx: wallImageDimensionsPx,
    topLeft: { x: xOffsetPx - wallImageDimensionsPx.width + 0.5, y: yOffsetPx },
  };
  const willCreateWallImageLeft = willCreateImageForCanvas({
    ...howToDrawWallImageLeft,
    globalCompositeOperation: "multiply",
  });

  const howToDrawWallImageRight = {
    src: wallImage.src,
    dimensionsPx: wallImageDimensionsPx,
    topLeft: { x: xOffsetPx + wallImageDimensionsPx.width - 0.5, y: yOffsetPx },
  };
  const willCreateWallImageRight = willCreateImageForCanvas({
    ...howToDrawWallImageRight,
    globalCompositeOperation: "multiply",
  });

  const willCreateImageToHideWallUnderFloor = willCreateImageForCanvas({
    ...howToDrawRoomImage,
    src: maskToHideWallUnderFloor,
    globalCompositeOperation: "destination-out",
  });

  let willCreateNormalMap = null;
  let willCreateReflectionMap = null;

  const willCreateDoorImages = willCreateDoorImagesForCanvas(
    canvasHeightWallOnlyPx,
    wallToDraw,
    pixelsPerCm
  );

  const willCreateWindowsImages = willCreateWindowImagesForCanvas(
    canvasHeightWallOnlyPx,
    wallToDraw,
    pixelsPerCm
  );

  const floorHeightPx = roomImage.floorHeightRatio * roomDimensionsPx.height;

  let willCreateFurnitureImage = willCreateFurnitureImageForCanvas(
    furnitureImage,
    canvasDimensionsPx,
    floorHeightPx,
    // Add some padding around the doors so the furniture isn't too close to it
    convertDoorsToRangesInPixels(wallToDraw, 20, pixelsPerCm),
    pixelsPerCm
  );

  // Create a promise that will resolve to a CanvasBlueprint object once doors and windows are created
  return new Promise((resolve) => {
    Promise.all([
      willCreateRoomImage,
      willCreateWallImage,
      willCreateWallImageLeft,
      willCreateWallImageRight,
      willCreateImageToHideWallUnderFloor,
      willCreateNormalMap,
      willCreateReflectionMap,
      willCreateDoorImages,
      willCreateWindowsImages,
      willCreateFurnitureImage,
    ]).then(
      ([
        roomImage,
        wallImage,
        wallImageLeft,
        wallImageRight,
        hideWallUnderFloorImage,
        normalMapImage,
        reflectionMapImage,
        doorImages,
        windowImages,
        furnitureImage,
      ]) => {
        const wallImagePostProcessedAndCropped = [
          // Draw the filters that should be applied to the wall only on the wall image
          [
            wallImage,
            ...(normalMapImage ? [normalMapImage] : []),
            ...(reflectionMapImage ? [reflectionMapImage] : []),
          ],
          // Hide the wall image (and the filters applied on it) under the floor
          hideWallUnderFloorImage,
        ];

        const wallImagePostProcessedAndCroppedLeft = [
          // Draw the filters that should be applied to the wall only on the wall image
          [
            wallImageLeft,
            ...(normalMapImage ? [normalMapImage] : []),
            ...(reflectionMapImage ? [reflectionMapImage] : []),
          ],
          // Hide the wall image (and the filters applied on it) under the floor
          hideWallUnderFloorImage,
        ];

        const wallImagePostProcessedAndCroppedRight = [
          // Draw the filters that should be applied to the wall only on the wall image
          [
            wallImageRight,
            ...(normalMapImage ? [normalMapImage] : []),
            ...(reflectionMapImage ? [reflectionMapImage] : []),
          ],
          // Hide the wall image (and the filters applied on it) under the floor
          hideWallUnderFloorImage,
        ];

        resolve({
          canvasDimensionsPx: canvasDimensionsPx,
          // Order matters, the farther the image is in the array, the more forward it will be
          // Images that are grouped as arrays inside the array will be drawn together, then applied on top of the previous image as one image
          imagesToDrawOnCanvas: [
            roomImage,
            useRepetition ? wallImagePostProcessedAndCroppedLeft : undefined,
            wallImagePostProcessedAndCropped,
            useRepetition ? wallImagePostProcessedAndCroppedRight : undefined,
            ...doorImages,
            ...windowImages,
            ...(furnitureImage ? [furnitureImage] : []), // Only add furnitures if one is defined
          ],
        });
      }
    );
  });
};
