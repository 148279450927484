import { ImageOfMeasuredContent } from "classes/Image";
import { getMatchingImage } from "helpers/ImageHelper";

import door1by1 from "resources/images/doors/door-220x220.png";
import door1by2 from "resources/images/doors/door-110x220.png";
import door1by3 from "resources/images/doors/door-73x220.png";
import door2by3 from "resources/images/doors/door-146x220.png";
import door2by1 from "resources/images/doors/door-440x220.png";

const allDoorsImages = [
  new ImageOfMeasuredContent(door1by1, { width: 220, height: 220 }),
  new ImageOfMeasuredContent(door1by2, { width: 110, height: 220 }),
  new ImageOfMeasuredContent(door1by3, { width: 73.3333, height: 220 }),
  new ImageOfMeasuredContent(door2by3, { width: 146.6666, height: 220 }),
  new ImageOfMeasuredContent(door2by1, { width: 440, height: 220 }),
];

/**
 * Get the door image whose sides are the closest to match the provided ratio
 * @param {number} heightOnWidthRatio the ratio obtained by dividing the height of door by it's width
 * @returns {string} the src of the door image that is the best match
 */
export const getMatchingDoorImage = (heightOnWidthRatio) => {
  return getMatchingImage(heightOnWidthRatio, allDoorsImages).imageSrc;
};
