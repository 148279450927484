import React, { useState } from "react";

// Components
import NavigationBar from "./NavigationBar";
import PanoramiquePage from "../pages/PanoramiquePage";
import ColoursPage from "../pages/ColoursPage";
import WallDimensionsPage from "../pages/wallDimensions/WallDimensionsPage";
import VisualizerPage from "../pages/visualizer/VisualizerPage";

// Helpers
import localizer, { setLanguage } from "localization/localizer";
import { motion } from "framer-motion";
import { panoramiques } from "../helpers/PanoramiqueConfig";

// Styling
import styled from "styled-components";

// Temporary
import PdfViewerPage from "tests/PdfViewerPage";

const Wrapper = styled(motion.div)`
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: 100vh;
`;

const steps = {
  panoramiquePage: "panoramiquePage",
  coloursPage: "colours",
  wallDimensionsPage: "wallDimensions",
  visualizerPage: "visualizer",
  // Uncomment this line to add a new page to see the pdf that will be downloaded by the user
  // the page will show up as "undefined" in the top bar because there is no field for it in the localizer, we don't really care since this is only for testing
  // To fill the pdf without having to make all the choices a user would normally have to do, you can fill the states of the Router initially with some proper values
  //pdfViewer: "pdfViewer",
};

const getMatchingPanoramiqueAndColor = (id) => {
  let matchedPanoramique = null;
  let matchedColor = null;

  panoramiques.forEach((panoramique) => {
    panoramique.colorVariants.forEach((variant) => {
      if (variant.code === id) {
        matchedColor = variant;
        matchedPanoramique = panoramique;
      }
    });
  });

  return { matchedPanoramique, matchedColor };
};

const Router = ({ panoramiqueReferenceId }) => {
  const { matchedPanoramique, matchedColor } = getMatchingPanoramiqueAndColor(
    panoramiqueReferenceId
  );

  const [enabledSteps, setEnabledSteps] = useState(
    matchedPanoramique && matchedColor
      ? [steps.panoramiquePage, steps.coloursPage]
      : [steps.panoramiquePage]
  );

  const [activeStep, setActiveStep] = useState(
    matchedPanoramique && matchedColor
      ? steps.wallDimensionsPage
      : steps.panoramiquePage
  );

  const [currentPanoramique, setCurrentPanoramique] =
    useState(matchedPanoramique);
  const [currentColor, setCurrentColor] = useState(matchedColor);
  const [currentWall, setCurrentWall] = useState(null);
  const [wallTechnicalImageUrl, setWallTechnicalImageUrl] = useState(null);
  const [wallImageOffset, setWallImageOffset] = useState(null);

  const [wasOnLastPage, setWasOnLastPage] = useState(false);

  // The purpose of this state is only to update the view when changing the language
  const [currentLanguage, setCurrentLanguage] = useState();

  const [useRepetition, setUseRepetition] = useState(false);

  const buttons = [];
  Object.keys(steps).forEach((key, index) => {
    buttons.push({
      key: key,
      active: activeStep === steps[key],
      enabled: enabledSteps.includes(steps[key]),
      onClick: () => {
        /**
         * Custom logic to check if the user was on the last page when going to the colourspage to switch colour
         * The desired flow is that after going directly to the second step to switch colour you jump back to the last page to see the result
         */
        if (
          activeStep === steps.visualizerPage &&
          steps[key] === steps.coloursPage
        ) {
          setWasOnLastPage(true);
        } else {
          setWasOnLastPage(false);
        }
        setActiveStep(steps[key]);
      },
      text: `${index + 1}. ${localizer.navigationBar[key]}`,
    });
  });

  let content = null;
  switch (activeStep) {
    default:
    case "panoramiquePage":
      content = (
        <PanoramiquePage
          onPanoramiqueSpecified={(panoramique) => {
            setCurrentPanoramique(panoramique);
            setActiveStep(steps.coloursPage);

            // We only enable the first two steps since we want the user to go through the other steps again to make sure the schematic image is rendered/updated in step 4
            setEnabledSteps((current) => [
              steps.panoramiquePage,
              steps.coloursPage,
            ]);
          }}
        />
      );
      break;
    case "colours":
      content = (
        <ColoursPage
          currentPanoramique={currentPanoramique}
          onColorSpecified={(color) => {
            setCurrentColor(color);

            // Custom logic to redirect to last page after switching colour
            if (wasOnLastPage) {
              setActiveStep(steps.visualizerPage);
              setWasOnLastPage(false);
            } else {
              setActiveStep(steps.wallDimensionsPage);
            }

            if (!enabledSteps.includes(steps.wallDimensionsPage)) {
              setEnabledSteps((current) => [
                ...current,
                steps.wallDimensionsPage,
              ]);
            }
          }}
        />
      );
      break;
    case "wallDimensions":
      content = (
        <WallDimensionsPage
          currentPanoramique={currentPanoramique}
          currentColor={currentColor}
          initialWallDimensions={currentWall}
          wallImageOffset={wallImageOffset}
          setUseRepetition={setUseRepetition}
          useRepetition={useRepetition}
          openWallShowcaseByDefault={enabledSteps.includes(
            steps.visualizerPage
          )}
          onWallDimensionsChanged={(wall) => {
            setCurrentWall(wall);
          }}
          onWallSubmittedByUser={(wall) => {
            setCurrentWall(wall);
          }}
          OnWallDimensionsConfirmed={(wallRenderImageUrl, imageOffset) => {
            setActiveStep(steps.visualizerPage);

            if (!enabledSteps.includes(steps.visualizerPage)) {
              setEnabledSteps((current) => [
                ...current,
                steps.visualizerPage,
                steps.pdfViewer,
              ]);
            }

            setWallTechnicalImageUrl(wallRenderImageUrl);
            setWallImageOffset(imageOffset);
          }}
        />
      );
      break;
    case "visualizer":
      content = (
        <VisualizerPage
          panoramiquePickedByUser={currentPanoramique}
          panoramiqueColorPickedByUser={currentColor}
          wallDefinedByUser={currentWall}
          wallTechnicalPlanImageUrl={wallTechnicalImageUrl}
          wallImageOffset={wallImageOffset}
          useRepetition={useRepetition}
        />
      );
      break;
    // This is only used for testing purposes, it is a fast way to see the pdf that gets created when the user downloads it
    case "pdfViewer":
      content = (
        <PdfViewerPage
          panoramique={currentPanoramique}
          colorVariant={currentColor}
          wall={currentWall}
          wallTechnicalPlanImageUrl={wallTechnicalImageUrl}
        />
      );
      break;
  }

  if (!content) {
    return null;
  }

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      exit={{ opacity: 0 }}
      language={currentLanguage}
    >
      <NavigationBar
        buttons={buttons}
        onLanguageChanged={(language) => {
          setLanguage(language);
          setCurrentLanguage(language);
        }}
      />
      {content}
    </Wrapper>
  );
};

export default Router;
